import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react';
import gql from 'graphql-tag';
import { parse, isValid, format } from 'date-fns';
import { useLazyQuery } from '@apollo/client';
import { styled } from 'styles';
import { rem } from 'polished';
import { css } from 'styled-components';
import { small, medium } from '../../../utils';
import { FormikInput } from '../../../Shared/Form/Formik';
import FieldError from '../../../Shared/Form/FieldError';
import DateInput from '../../DateInput';
import { UserDetails } from '../SignUp';
import { tracking } from '../../../App/Tracking';
import { VIRGIN_PULSE_PARTNER_NAME } from '../../VirginPulse/consts';
import BodyText from '../../../Shared/Typography/BodyText';
import {
  AuthWrapper,
  AuthWrapperBlock,
  AuthWrapperContent,
  AuthWrapperSubtitle,
  AuthWrapperTitle,
} from '../../AuthWrapper';
import DateInputValidation from './DateInputValidation';

import {
  StyledForm,
  StyledFormFieldLabel,
  StyledSubmitButton,
} from './CommonFormStyledComponents';

const StyledLink = styled.a`
  text-decoration: none;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
`;

export enum ERROR_MESSAGES {
  INVALID_DATE = 'Your date of birth is not valid. Please check it and try again. For further help visit our support page.',
  NOT_ELIGIBLE = 'We couldn’t find your Virgin Pulse profile. Please check your details and try again. For further help reach out to the Virgin Pulse Member Services Support or email them at',
  DEFAULT = 'Please get in touch with',
}

export enum SUPPORT_EMAILS {
  UNMIND = 'support@unmind.com',
  VIRGIN_PULSE = 'support@virginpulse.com',
}

interface ErrorProps {
  errorMessage: string;
  mailtoLink: string;
}

const ErrorWithMailtoLink = ({ errorMessage, mailtoLink }: ErrorProps) => (
  <div>
    {errorMessage}{' '}
    <StyledLink target="_blanks" href={`mailto:${mailtoLink}`}>
      {mailtoLink}
    </StyledLink>
    .
  </div>
);

const FirstNameInputWrapper = styled.div`
  flex: 0;
`;

const SubmitWrapper = styled.div`
  margin-top: ${rem('16px')};
`;

const RestyledSubmitButton = styled(StyledSubmitButton)`
  margin: ${rem('12px')} 0 ${rem('16px')} 0;
`;

const StyledAuthWrapperContent = styled(AuthWrapperContent)`
  padding: ${rem(16)} 0 0 0;

  ${small(css`
    padding: ${rem(24)} 0 0 0;
  `)}

  ${medium(css`
    padding: ${rem(32)} 0 0 0;
  `)}
`;

const Smallprint = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
  forwardedAs: 'p',
}))`
  max-width: ${rem(470)};
`;

export interface VirginPulsePartnerEligibilityFormProps {
  onConfirmedEligibility(values: UserDetails): void;
  isInitialValid?: boolean;
  initialValues?: {
    firstName: string;
    lastName: string;
    dateDay: string;
    dateMonth: string;
    dateYear: string;
  };
  subdomain: string;
}

const EligibilitySchema = Yup.object().shape({
  firstName: Yup.string().required('Please enter your first name'),
  lastName: Yup.string().required('Please enter your last name'),
  ...DateInputValidation,
});

export const VALIDATE_ELIGIBILITY_QUERY = gql`
  query ValidateVirginPulseEligibility(
    $input: ValidateVirginPulseEligibilityInput!
  ) {
    validateVirginPulseEligibility(input: $input) {
      ... on ValidateVirginPulseEligibilitySuccess {
        isEligible
      }
      ... on ValidateVirginPulseEligibilityNotFound {
        isEligible
        message
      }
    }
  }
`;

const VirginPulsePartnerEligibilityForm = ({
  isInitialValid = false,
  initialValues = {
    firstName: '',
    lastName: '',
    dateDay: '',
    dateMonth: '',
    dateYear: '',
  },
  subdomain,
  onConfirmedEligibility,
}: VirginPulsePartnerEligibilityFormProps) => {
  const [error, setError] = useState<string | React.ReactNode>();
  const [submittedEligibilityData, setSubmittedEligibilityData] = useState<{
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
  }>({
    firstName: undefined,
    lastName: undefined,
    dateOfBirth: undefined,
  });

  const handleValidateEligibilitySuccess = () => {
    setError('');

    tracking.track('employee-authentication', {
      subdomain,
      verificationType: 'vp-user-identifier',
      verificationValid: true,
      partner: VIRGIN_PULSE_PARTNER_NAME,
    });

    onConfirmedEligibility(submittedEligibilityData);
  };

  const handleValidateEligibilityFailure = () => {
    setError(
      <ErrorWithMailtoLink
        errorMessage={ERROR_MESSAGES.NOT_ELIGIBLE}
        mailtoLink={SUPPORT_EMAILS.VIRGIN_PULSE}
      />,
    );

    tracking.track('employee-authentication', {
      subdomain,
      verificationType: 'vp-user-identifier',
      verificationValid: false,
      partner: VIRGIN_PULSE_PARTNER_NAME,
    });

    setSubmittedEligibilityData({
      firstName: undefined,
      lastName: undefined,
      dateOfBirth: undefined,
    });
  };

  const [validateVirginPulseEligibility, { loading }] = useLazyQuery(
    VALIDATE_ELIGIBILITY_QUERY,
    {
      onCompleted: data => {
        const {
          validateVirginPulseEligibility: { isEligible },
        } = data;

        if (isEligible) {
          handleValidateEligibilitySuccess();
        } else {
          handleValidateEligibilityFailure();
        }
      },
      onError: () => {
        setError(
          <ErrorWithMailtoLink
            errorMessage={ERROR_MESSAGES.DEFAULT}
            mailtoLink={SUPPORT_EMAILS.UNMIND}
          />,
        );
      },
    },
  );

  const onSubmitForm = (values: typeof initialValues) => {
    const { firstName, lastName, dateMonth, dateDay, dateYear } = values;

    try {
      const formattedDate = `${dateYear}-${dateMonth}-${dateDay}`;

      const parsedDate = parse(formattedDate, 'yyyy-M-d', new Date());
      const isValidDate = isValid(parsedDate);

      if (!isValidDate) {
        throw new Error('Invalid Date provided');
      }

      const dateOfBirth = format(parsedDate, 'yyyy-MM-dd');

      validateVirginPulseEligibility({
        variables: {
          input: {
            firstName,
            lastName,
            dateOfBirth,
            subdomain,
          },
        },
      });

      setSubmittedEligibilityData({
        firstName,
        lastName,
        dateOfBirth,
      });
    } catch (e) {
      setError(ERROR_MESSAGES.INVALID_DATE);

      setSubmittedEligibilityData({
        firstName: undefined,
        lastName: undefined,
        dateOfBirth: undefined,
      });
    }
  };

  return (
    <AuthWrapper subdomain={subdomain}>
      <AuthWrapperBlock>
        <AuthWrapperTitle>Connect to your Unmind space</AuthWrapperTitle>
        <AuthWrapperSubtitle>
          We just need to verify your Virgin Pulse membership before you get
          started.
        </AuthWrapperSubtitle>
        <StyledAuthWrapperContent>
          <Formik
            isInitialValid={isInitialValid}
            initialValues={initialValues}
            validationSchema={EligibilitySchema}
            onSubmit={onSubmitForm}
          >
            {({ isValid: isFormValid, errors, touched }) => (
              <StyledForm>
                <FirstNameInputWrapper>
                  <StyledFormFieldLabel htmlFor="firstName">
                    First name
                  </StyledFormFieldLabel>
                  <FormikInput
                    name="firstName"
                    placeholder="First name"
                    aria-label="First name"
                    aria-invalid={!errors.firstName}
                    type="firstName"
                    autoComplete="given-name"
                    expandHeight={false}
                  />
                </FirstNameInputWrapper>
                <StyledFormFieldLabel htmlFor="lastName">
                  Last name
                </StyledFormFieldLabel>
                <FormikInput
                  name="lastName"
                  placeholder="Last name"
                  aria-label="Last name"
                  aria-invalid={!errors.lastName}
                  type="lastName"
                  autoComplete="family-name"
                  expandHeight={false}
                />
                <DateInput
                  label="Date of Birth"
                  error={
                    (touched.dateDay && errors.dateDay) ||
                    (touched.dateMonth && errors.dateMonth) ||
                    (touched.dateYear && errors.dateYear) ||
                    undefined
                  }
                  isDateOfBirth={true}
                />
                <SubmitWrapper>
                  <Smallprint>
                    We only use your Date of Birth to confirm that you are a
                    Virgin Pulse member.
                  </Smallprint>
                  <RestyledSubmitButton
                    type="submit"
                    label="Connect"
                    aria-disabled={!isFormValid}
                    loading={loading}
                  />
                </SubmitWrapper>
                {error ? <FieldError message={error} /> : null}
              </StyledForm>
            )}
          </Formik>
        </StyledAuthWrapperContent>
      </AuthWrapperBlock>
    </AuthWrapper>
  );
};

export default VirginPulsePartnerEligibilityForm;

import React from 'react';
import { IconProps } from '../IconProps';

const SvgMagicBubble = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg
    fill="none"
    viewBox="0 0.32 39.36 39.36"
    width="1em"
    height="1em"
    {...props}
  >
    <rect y={0.321} width={39.357} height={39.357} rx={19.679} fill="#FFFCBD" />
    <path
      d="M9 30.678l14.786-14.785M27.071 12.607l1.643-1.643M22.964 11.786V9.32M27.893 16.714h2.464M26.25 20l1.643 1.643M18.036 11.786l1.643 1.643"
      stroke="#0D0300"
      strokeOpacity={0.95}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgMagicBubble;

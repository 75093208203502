import React from 'react';
import getSubdomainFromUrl from '../../utils/getSubdomainFromUrl';
import { useSubdomainInfo } from '../SignUp/useSubdomainInfo';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import { AuthTypeEnum } from '../../__generated__/globalTypes';
import AuthMethod from '../AuthMethod/AuthMethod';
import LoginViaUsernamePassword, {
  LoginViaUsernamePasswordProps,
} from './LoginViaUsernamePassword';
import LoginViaSSO, { LoginViaSSOProps } from './LoginViaSSO';

export interface LoginProps
  extends LoginViaUsernamePasswordProps,
    LoginViaSSOProps {}

export function Login(props: LoginProps) {
  const subdomain = getSubdomainFromUrl();

  const { group, loading: authTypeLoading } = useSubdomainInfo({
    subdomain,
  });

  if (authTypeLoading) {
    return <LoadingIndicator />;
  }

  const { authType } = group ?? { authType: null };

  const isUsingSSO = authType === AuthTypeEnum.AUTH0_SSO;
  const usingMixedMode = authType === AuthTypeEnum.MIXED_MODE;

  if (isUsingSSO) {
    return <LoginViaSSO {...props} />;
  } else if (usingMixedMode) {
    return <AuthMethod />;
  }

  return <LoginViaUsernamePassword {...props} />;
}

export default Login;

import { useLazyQuery } from '@apollo/client';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import { rem } from 'polished';
import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { css, styled } from 'styles';
import RoutePath from '../../../App/RoutePath';
import { tracking } from '../../../App/Tracking';
import BodyText from '../../../Shared/Typography/BodyText';
import { small, medium } from '../../../utils';
import {
  AuthWrapper,
  AuthWrapperBlock,
  AuthWrapperContent,
  AuthWrapperSubtitle,
  AuthWrapperTitle,
} from '../../AuthWrapper';
import EmailInputForm from './EmailInputForm';

const SupportText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize12,
    theme.typography.fontSizes.fontSize12,
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
  ],
}))`
  ${small(css`
    margin-top: ${rem('10px')};
    max-width: ${({ theme }) => (7 / theme.grid.columns.tablet) * 100}%;
  `)}

  ${medium(css`
    max-width: 100%;
  `)}
`;

const SupportLink = styled.a`
  color: ${({ theme }) => theme.colors.text.link};
  font-weight: bold;
  text-decoration: none;
`;

export enum FindOrganisationSourceTypeValues {
  SIGNUP = 'SIGNUP',
  SIGNIN = 'SIGNIN',
}

interface FormFields {
  emailInput: string;
}

export const FIND_ORGANISATION_QUERY = gql`
  query FindTeam($email: String!, $source: FindTeamSourceType) {
    findTeam(email: $email, source: $source)
  }
`;

const FindOrganisationForm = () => {
  const history = useHistory();
  const { t: translate } = useTranslation<Namespace<'logged_out' | 'shared'>>([
    'logged_out',
    'shared',
  ]);
  const formikRef = useRef<Formik<FormFields>>(null);
  const [error, setError] = useState('');
  const location = useLocation();
  const originPath = new URLSearchParams(location.search).get('path');
  const isSignUpFlow = Boolean(originPath?.includes('signup'));

  const [findOrganisation, { loading }] = useLazyQuery(
    FIND_ORGANISATION_QUERY,
    {
      onCompleted: () => {
        tracking.track('search-organisation-submitted', {
          origin: isSignUpFlow ? 'signup' : 'login',
        });

        history.push(RoutePath.FindYourOrganisationConfirmation, {
          email: formikRef?.current?.state.values.emailInput,
          isSignUpFlow,
        });
      },
      onError: err => {
        setError(
          err.message.toLowerCase().indexOf('rate limit reached') >= 0
            ? translate(
                'logged_out:sign_up.forms.find_organisation.errors.rate_limit_exceeded_message',
              )
            : translate('shared:errors.messages.failed'),
        );
      },
    },
  );

  const onSubmit = (values: FormFields) => {
    findOrganisation({
      variables: {
        email: values.emailInput,
        source: isSignUpFlow
          ? FindOrganisationSourceTypeValues.SIGNUP
          : FindOrganisationSourceTypeValues.SIGNIN,
      },
    });
  };

  return (
    <AuthWrapper>
      <AuthWrapperBlock data-testid="find-organisation-form">
        <AuthWrapperTitle data-testid="form-title">
          {translate('logged_out:sign_up.forms.find_organisation.heading')}
        </AuthWrapperTitle>
        <AuthWrapperSubtitle data-testid="form-subtitle">
          {translate('logged_out:sign_up.forms.find_organisation.subtitle')}
        </AuthWrapperSubtitle>
        <AuthWrapperContent>
          <EmailInputForm
            submitLabel={translate(
              'logged_out:sign_up.forms.find_organisation.submit_label',
            )}
            onSubmit={onSubmit}
            setError={setError}
            error={error}
            loading={loading}
            formikRef={formikRef}
          />
          <SupportText data-testid="support-text">
            {Trans({
              t: translate,
              i18nKey:
                'logged_out:sign_up.forms.find_organisation.support_text',
              defaults:
                "We can only recognise work email addresses. If you don't have one, let us know the name of your company by emailing <support_link>support@unmind.com</support_link> and we'll see what we can do.",
              components: {
                support_link: (
                  <SupportLink
                    data-testid="support-email"
                    href="mailto:support@unmind.com"
                  />
                ),
              },
            })}
          </SupportText>
        </AuthWrapperContent>
      </AuthWrapperBlock>
    </AuthWrapper>
  );
};

export default FindOrganisationForm;

import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { css, styled } from 'styles';
import { OpenExternalLink } from 'icons';
import { BodyText } from '../../Shared/Typography';
import { small, medium, large } from '../../utils';
import tracking from '../../App/Tracking/tracking';
import PanelIllustration from './Assets/panel-illustration.svg';

const EmailClientLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${small(css`
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  `)};
`;

const OpenExternalLinkIcon = styled(OpenExternalLink).attrs(() => ({
  primaryColor: 'currentColor',
}))`
  margin-right: 2px;
`;

const EmailClientLink = styled(BodyText).attrs(({ theme }) => ({
  forwardedAs: 'a',
  sizes: [theme.typography.fontSizes.fontSize14],
  weight: 'medium',
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: 16px;
  text-decoration: none;
  height: 34px;
  width: 142px;
  margin-right: 8px;
`;

const EmailConfirmationRightPanel = styled.div`
  flex: 1;
  position: relative;
`;

const EmailConfirmationPanelIllustration = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: ${rem(520)};

  ${large(css`
    max-width: ${rem(630)};
  `)}
`;

export const EmailConfirmationHelperText = styled(BodyText).attrs(
  ({ theme }) => ({
    forwardedAs: 'p',
    sizes: [theme.typography.fontSizes.fontSize18],
  }),
)`
  margin-top: -${rem(8)};
  ${medium(css`
    margin-top: -${rem(16)};
  `)}
`;

export const EmailConfirmationRightBlock = () => (
  <EmailConfirmationRightPanel>
    <EmailConfirmationPanelIllustration src={PanelIllustration} alt="" />
  </EmailConfirmationRightPanel>
);

const GMAIL_ROOT_URL = 'https://mail.google.com/mail/u';
const GMAIL_SENDER_PARAM = 'from%3A%40';
const GMAIL_LOCATION_PARAM_ANYWHERE = 'in%3Aanywhere';
const GMAIL_RECEIVED_DATE_PARAM_1_DAY = 'newer_than%3A1d';

const UNMIND_VERIFICATION_SENDER_EMAIL_ADDRESS = 'support@unmind.com';

// create a sniper link to encourage findability of the verification email
// example url
// https://mail.google.com/mail/u/testing@unmind.com/#search/from%3A%40support@unmind.com+in%3Aanywhere+newer_than%3A1d
export const generateGmailSniperLink = (userEmailAddress?: string) => {
  if (userEmailAddress) {
    return `${GMAIL_ROOT_URL}/${userEmailAddress}/#search/${GMAIL_SENDER_PARAM}${UNMIND_VERIFICATION_SENDER_EMAIL_ADDRESS}+${GMAIL_LOCATION_PARAM_ANYWHERE}+${GMAIL_RECEIVED_DATE_PARAM_1_DAY}`;
  }

  return `${GMAIL_ROOT_URL}/#search/${GMAIL_SENDER_PARAM}${UNMIND_VERIFICATION_SENDER_EMAIL_ADDRESS}+${GMAIL_LOCATION_PARAM_ANYWHERE}+${GMAIL_RECEIVED_DATE_PARAM_1_DAY}`;
};

type EmailConfirmationFooterProps = {
  userEmailAddress?: string;
};

export const EmailConfirmationFooter = ({
  userEmailAddress,
}: EmailConfirmationFooterProps) => {
  const { t: translate } = useTranslation<Namespace<'shared'>>('shared');
  const trackEmailCTAClicked = () => tracking.track('open-email-cta-clicked');

  return (
    <>
      <EmailClientLinksContainer>
        <EmailClientLink
          onClick={trackEmailCTAClicked}
          href={generateGmailSniperLink(userEmailAddress)}
          target="_blank"
          rel="noopener noreferrer"
          data-testid="email-confirmation-gmail-link"
          aria-label={translate(
            'email_confirmation.footer.email_client.gmail.a11y_label',
          )}
        >
          <OpenExternalLinkIcon />
          {translate('email_confirmation.footer.email_client.gmail.text')}
        </EmailClientLink>
        <EmailClientLink
          onClick={trackEmailCTAClicked}
          href="https://outlook.live.com/owa/"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="email-confirmation-outlook-link"
          aria-label={translate(
            'email_confirmation.footer.email_client.outlook.a11y_label',
          )}
        >
          <OpenExternalLinkIcon />
          {translate('email_confirmation.footer.email_client.outlook.text')}
        </EmailClientLink>
      </EmailClientLinksContainer>
    </>
  );
};

import React, { useState } from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { styled } from 'styles';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  AuthWrapper,
  AuthWrapperBlock,
  AuthWrapperContent,
  AuthWrapperSubtitle,
  AuthWrapperTitle,
} from '../AuthWrapper';
import {
  StyledForm,
  StyledFormFieldLabel,
  StyledSubmitButton,
} from '../SignUp/Forms/CommonFormStyledComponents';
import RoutePath from '../../App/RoutePath';
import {
  FormikValidatedPasswordInput,
  validateFieldNotEmpty,
} from '../../Shared/Form/Formik';
import { AlertBox } from '../../Shared/Form';
import getSubdomainFromUrl from '../../utils/getSubdomainFromUrl';
import { useSubdomainInfo } from '../SignUp/useSubdomainInfo';
import { LocalGeneralError } from '../../Shared/AlertView';
import { BodyText } from '../../Shared/Typography';
import {
  verifyAccountOwnership,
  verifyAccountOwnershipVariables,
} from './__generated__/verifyAccountOwnership';

const ObfuscatedEmailText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize18],
}))`
  margin-bottom: 30px;
`;

interface FormFields {
  password: string;
}

const VERIFY_ACCOUNT_OWNERSHIP_MUTATION = gql`
  mutation verifyAccountOwnership(
    $userId: String!
    $customIdentifier: String!
    $auth0UserId: String!
    $auth0State: String!
    $password: String!
  ) {
    verifyAccountOwnership(
      userId: $userId
      customIdentifier: $customIdentifier
      auth0UserId: $auth0UserId
      auth0State: $auth0State
      password: $password
    )
  }
`;

const VerifyAccountOwnership = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string>('');

  const { t: translate } = useTranslation(['logged_out', 'shared']);

  const urlSearchParams = new URLSearchParams(location.search);
  const userId = urlSearchParams.get('unmind_user_id');
  const employeeId = urlSearchParams.get('employee_id');
  const auth0UserId = urlSearchParams.get('auth0_user_id');
  const emailHint = urlSearchParams.get('email_hint');
  const auth0State = urlSearchParams.get('state');

  const [verifyAccountOwnershipMutation, { loading }] =
    useMutation<verifyAccountOwnership>(VERIFY_ACCOUNT_OWNERSHIP_MUTATION, {
      onCompleted: async data => {
        const auth0RedirectToken = data.verifyAccountOwnership;
        const resumeAuthFlowTokenParam = `?state=${auth0State}&auth0_redirect_token=${auth0RedirectToken}`;
        const resumeAuthFlowUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/continue${resumeAuthFlowTokenParam}`;
        window.location.assign(resumeAuthFlowUrl);
      },
      onError: err => {
        if (err.message.toLowerCase().indexOf('invalid credentials') > 0) {
          setError(
            translate(
              'logged_out:verify_account_ownership.errors.password_no_match',
            ),
          );
        } else if (
          err.message.toLowerCase().indexOf('account temporarily disabled') > 0
        ) {
          setError(
            translate(
              'logged_out:verify_account_ownership.errors.account_locked',
            ),
          );
        } else {
          setError(translate('shared:errors.messages.unexpected'));
        }
      },
    });

  const subdomain = getSubdomainFromUrl();
  const { groupName } = useSubdomainInfo({ subdomain });

  const isMissingParams =
    !emailHint || !employeeId || !auth0State || !userId || !auth0UserId;
  if (isMissingParams) {
    return <LocalGeneralError />;
  }

  const onSubmit = async (values: FormFields) => {
    void verifyAccountOwnershipMutation({
      variables: {
        userId,
        auth0State,
        auth0UserId,
        customIdentifier: employeeId,
        password: values.password,
      } as verifyAccountOwnershipVariables,
    });
  };

  const onPasswordDisplayToggle = () => {
    setShowPassword(!showPassword);
  };

  return (
    <AuthWrapper subdomain={groupName}>
      <AuthWrapperBlock>
        <AuthWrapperTitle>
          {translate('logged_out:verify_account_ownership.title')}
        </AuthWrapperTitle>
        <AuthWrapperSubtitle>
          {translate('logged_out:verify_account_ownership.subtitle')}
        </AuthWrapperSubtitle>
        <AuthWrapperContent>
          <div>
            <Formik
              initialValues={{
                password: '',
              }}
              onSubmit={onSubmit}
            >
              {({ isValid }) => (
                <StyledForm>
                  <ObfuscatedEmailText
                    aria-label={translate(
                      'logged_out:verify_account_ownership.email_field.a11y_label',
                    )}
                  >
                    {emailHint}
                  </ObfuscatedEmailText>
                  <StyledFormFieldLabel htmlFor="password">
                    {translate(
                      'logged_out:verify_account_ownership.password_field.label',
                    )}
                  </StyledFormFieldLabel>
                  <FormikValidatedPasswordInput
                    id="password"
                    showValidator={false}
                    data-testid="password-input"
                    name="password"
                    placeholder={translate(
                      'logged_out:verify_account_ownership.password_field.placeholder_text',
                    )}
                    type={showPassword ? 'text' : 'password'}
                    expandHeight={false}
                    onToggleSecureText={onPasswordDisplayToggle}
                    validate={e =>
                      validateFieldNotEmpty(e, 'Password required')
                    }
                  />
                  <Link
                    to={RoutePath.ForgotPassword}
                    onClick={() => console.log('forgot password')}
                  >
                    {translate(
                      'logged_out:verify_account_ownership.password_field.forgot_password',
                    )}
                  </Link>
                  <StyledSubmitButton
                    data-testid="verify-account-button"
                    type="submit"
                    label={translate(
                      'logged_out:verify_account_ownership.continue_button.label',
                    )}
                    aria-label={translate(
                      'logged_out:verify_account_ownership.continue_button.a11y_label',
                    )}
                    disabled={!isValid}
                    loading={loading}
                  />
                  {error ? (
                    <AlertBox alertType="failed" message={error} />
                  ) : null}
                </StyledForm>
              )}
            </Formik>
          </div>
        </AuthWrapperContent>
      </AuthWrapperBlock>
    </AuthWrapper>
  );
};

export default VerifyAccountOwnership;

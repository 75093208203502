import { Formik, Field, FieldProps } from 'formik';
import React, { useState } from 'react';
import { styled, css } from 'styles';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import { FormikValidatedPasswordInput as PasswordInput } from '../../../Shared/Form/Formik';
import { HealthDataConsentCheckbox, MarketingCheckbox } from '../Checkboxes';
import {
  getValidationResult,
  isPasswordValid,
} from '../../../Shared/ValidatorMatch/PasswordValidator';
import PasswordValidationItem from '../../PasswordValidationItem';
import {
  AuthWrapperBlock,
  AuthWrapperContent,
  AuthWrapperTitle,
  AuthWrapper,
} from '../../AuthWrapper';
import { medium } from '../../../utils';
import { AlertBox } from '../../../Shared/Form';
import { tracking } from '../../../App/Tracking';
import { TsAndCsStatement } from '../TsAndCsStatement';
import {
  StyledForm,
  StyledFormFieldLabel,
  StyledSubmitButton,
} from './CommonFormStyledComponents';

const FormikValidatedPasswordInput = styled(PasswordInput)`
  margin-bottom: ${rem(16)};
`;

const StyledTsAndCsStatement = styled(TsAndCsStatement)`
  margin-bottom: ${rem(16)};
`;

const FormWrapper = styled.div`
  flex: 1;
`;

const PasswordValidationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${rem(24)};
`;

const PasswordValidationItemWrapper = styled.div`
  width: 50%;
  margin-bottom: ${rem(16)};
  ${medium(css`
    width: 100%;
  `)}
`;

const CheckboxWrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: ${rem(16)};
  }
`;

export interface EnterPasswordFormProps {
  onSubmit(password: string, marketingConsent?: boolean): Promise<void>;
  error?: string;
  subdomain?: string;
  loading?: boolean;
  name?: string;
  progressIndicator: React.ReactElement;
  partner: string | null;
  showCheckboxes?: boolean;
}

const EnterPasswordForm = ({
  onSubmit,
  error,
  subdomain,
  loading,
  name,
  progressIndicator,
  partner,
  showCheckboxes = true,
}: EnterPasswordFormProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t: translate } = useTranslation([
    'logged_out',
    'shared',
    'password_validation',
  ]);

  const onPasswordDisplayToggle = (displayed: boolean) => {
    tracking.track('password-displayed-toggled', {
      subdomain: subdomain,
      displayed,
      partner,
    });

    setShowPassword(!showPassword);
  };

  return (
    <AuthWrapper subdomain={name} progressBar={progressIndicator}>
      <AuthWrapperBlock data-testid="set-password-page">
        <AuthWrapperTitle data-testid="form-title">
          {translate('logged_out:sign_up.forms.password_form.heading')}
        </AuthWrapperTitle>
        <AuthWrapperContent>
          <Formik
            validate={values => {
              if (!isPasswordValid(values.password)) {
                return {
                  password: translate(
                    'shared:forms.validate_fields.invalid_password.text',
                  ),
                };
              }

              if (showCheckboxes && !values.givenHealthDataConsent) {
                return {
                  givenHealthDataConsent: false,
                };
              }

              return {};
            }}
            initialValues={{
              password: '',
              marketingOptIn: false,
              givenHealthDataConsent: false,
            }}
            onSubmit={async ({ password, marketingOptIn }) => {
              if (showCheckboxes) {
                // only pass through the default value for marketing opt in if we're showing it on this page
                await onSubmit(password, marketingOptIn);
              }

              // otherwise pass undefined, and rely on the value stored in user details
              // from the privacy page
              else {
                await onSubmit(password);
              }
            }}
          >
            {({ isValid, values, setFieldValue }) => {
              const { length, specialchar, numbers, uppercase, lowercase } =
                getValidationResult(values.password);

              const validations = [
                {
                  testID: 'length',
                  label: translate('password_validation:characters'),
                  valid: length,
                },
                {
                  testID: 'uppercase',
                  label: translate('password_validation:uppercase'),
                  valid: uppercase,
                },
                {
                  testID: 'special-character',
                  label: translate('password_validation:specialchar'),
                  valid: specialchar,
                },
                {
                  testID: 'lowercase',
                  label: translate('password_validation:lowercase'),
                  valid: lowercase,
                },
                {
                  testID: 'number',
                  label: translate('password_validation:numbers'),
                  valid: numbers,
                },
              ];

              return (
                <StyledForm>
                  <FormWrapper>
                    <StyledFormFieldLabel htmlFor="password">
                      {translate(
                        'logged_out:sign_up.forms.password_form.password_field.label',
                      )}
                    </StyledFormFieldLabel>
                    <div>
                      <FormikValidatedPasswordInput
                        showValidator={false}
                        data-testid="password-input"
                        autoComplete="new-password"
                        name="password"
                        placeholder={translate(
                          'logged_out:sign_up.forms.password_form.password_field.label',
                        )}
                        aria-label={translate(
                          'logged_out:sign_up.forms.password_form.password_field.label',
                        )}
                        type={showPassword ? 'text' : 'password'}
                        onToggleSecureText={onPasswordDisplayToggle}
                      />
                    </div>
                    <PasswordValidationWrapper>
                      {validations.map(validator => (
                        <PasswordValidationItemWrapper key={validator.label}>
                          <PasswordValidationItem
                            data-testid={`validation-item-${validator.testID}`}
                            label={validator.label}
                            isValid={validator.valid}
                          />
                        </PasswordValidationItemWrapper>
                      ))}
                    </PasswordValidationWrapper>
                  </FormWrapper>
                  <div>
                    {showCheckboxes ? (
                      <CheckboxWrapper>
                        <Field name="givenHealthDataConsent">
                          {({ field }: FieldProps) => (
                            <HealthDataConsentCheckbox
                              {...field}
                              id="sign-up-health-data-consent"
                              setFieldValue={setFieldValue}
                              checked={field.value as boolean}
                            />
                          )}
                        </Field>
                        <Field name="marketingOptIn">
                          {({ field }: FieldProps) => (
                            <MarketingCheckbox
                              {...field}
                              id="sign-up-marketing"
                              setFieldValue={setFieldValue}
                              checked={field.value as boolean}
                            />
                          )}
                        </Field>
                      </CheckboxWrapper>
                    ) : null}

                    <StyledSubmitButton
                      data-testid="submit-button"
                      type="submit"
                      loading={loading}
                      label={translate(
                        'logged_out:sign_up.forms.password_form.submit_button.label',
                      )}
                      disabled={!isValid}
                    />
                    <StyledTsAndCsStatement
                      subdomain={subdomain}
                      partner={partner}
                    />
                    {error ? (
                      <AlertBox alertType="failed" message={error} />
                    ) : null}
                  </div>
                </StyledForm>
              );
            }}
          </Formik>
        </AuthWrapperContent>
      </AuthWrapperBlock>
    </AuthWrapper>
  );
};

export default EnterPasswordForm;

import { LDUser } from 'launchdarkly-js-sdk-common';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { getUserLocale } from '../i18n/getUserLocale';
import getSubdomainFromUrl from '../utils/getSubdomainFromUrl';
import { FeatureFlagUserAttrs, WithFeatureFlagUserContextProps } from './types';

export const LAUNCH_DARKLY_USER_KEY = 'launch-darkly-user';

/*
  The Launch Darkly SDK is slightly different between React and React Native.
  In the React Native SDK, a key is required for both anonymous and non-anonymous users.
  In the React SDK, the key can be omitted when anonymous is true.
*/
const withFeatureFlagUserContext =
  <P extends Record<string, unknown>>(
    WrappedComponent: React.ComponentType<P & WithFeatureFlagUserContextProps>,
  ) =>
  (props: P) => {
    const client = useLDClient();

    const anonymizeFeatureFlagUser = async () => {
      if (client) {
        localStorage.removeItem(LAUNCH_DARKLY_USER_KEY);
        const subdomain = getSubdomainFromUrl();
        const customAttrs = subdomain ? { custom: { subdomain } } : {};
        const userData: LDUser = { anonymous: true, ...customAttrs };

        localStorage.setItem(LAUNCH_DARKLY_USER_KEY, JSON.stringify(userData));

        await client.waitForInitialization();
        // In client-side LaunchDarkly SDKs, the identify feature allows you to change
        // user context as they are configured to operate for one user at a time
        await client.identify(userData);
      } else {
        console.info('LDClient not available');
      }
    };

    const identifyFeatureFlagUser = async ({
      subdomain,
      userId,
      userTraits = [''],
    }: FeatureFlagUserAttrs) => {
      if (client) {
        const locale = getUserLocale();

        const customAttrs = {
          custom: { subdomain, userTraits, locale },
        };

        const userData: LDUser = {
          anonymous: false,
          key: userId,
          ...customAttrs,
        };

        localStorage.setItem(LAUNCH_DARKLY_USER_KEY, JSON.stringify(userData));
        await client.waitForInitialization();
        await client.identify(userData);
      } else {
        console.info('LDClient not available');
      }
    };

    return (
      <WrappedComponent
        identifyFeatureFlagUser={identifyFeatureFlagUser}
        anonymizeFeatureFlagUser={anonymizeFeatureFlagUser}
        {...props}
      />
    );
  };

export default withFeatureFlagUserContext;

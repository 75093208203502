import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { isMSTeams } from 'utils/MSTeams';
import RoutePath from 'App/RoutePath';
import BackButton from 'Shared/Button/BackButton';
import { getAuthErrorMessage } from '../Login/AuthError';
import { AlertBox } from '../../Shared/Form';
import { AuthWrapperHelpCentre } from './AuthWrapperHelpCentre';
import {
  AuthContent,
  AuthWrapperFooter,
  AuthWrapperGrid,
  SubdomainHeader,
  AlertContainer,
  Container,
} from './AuthWrapper.styled';

export { AuthWrapperHelpCentre } from './AuthWrapperHelpCentre';

interface AuthWrapperProps {
  children: ReactNode;
  subdomain?: string;
  progressBar?: ReactNode;
  showFooter?: boolean;
}

export const AuthWrapper = ({
  children,
  subdomain,
  progressBar,
  showFooter = true,
}: AuthWrapperProps) => {
  const urlSearchParams = new URLSearchParams(location.search);
  const { errorCode, errorMessage } = getAuthErrorMessage(urlSearchParams);
  const { t: translate } = useTranslation('logged_out');

  return (
    <AuthWrapperGrid>
      <AuthContent>
        <Container>
          {/* MS teams users can't tab backwards so they need to be shown a clickable button */}
          {isMSTeams() && (
            <BackButton linkToDefault={RoutePath.StartSplitScreen} />
          )}
          {subdomain ? (
            <SubdomainHeader data-testid="business-pill">
              {subdomain}
            </SubdomainHeader>
          ) : null}
        </Container>
        {errorCode ? (
          <AlertContainer>
            <AlertBox
              alertType="failed"
              // the below translate function expects a TemplateStringsArray typed value, however casting errorMessage to that type
              // doesn't stop the linter complaining, so we have to cast as any here
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              message={translate(errorMessage as any)}
            />
          </AlertContainer>
        ) : null}
        {progressBar}
        {children}
        {showFooter && (
          <AuthWrapperFooter>
            <AuthWrapperHelpCentre />
          </AuthWrapperFooter>
        )}
      </AuthContent>
    </AuthWrapperGrid>
  );
};

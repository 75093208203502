import isNil from 'lodash/isNil';
import { Errors } from '../../Shared/Errors';

export enum AuthStatus {
  ERROR,
  DEGRADED,
  SUCCESS,
}

export interface AuthApiResponse {
  activationDate: string | null;
  id: string;
  token: string;
  iris_token: string;
  email: string;
  firstName: string;
  lastName: string;
  userType: {
    name: string;
  };
  permissions: {
    value: string;
  };
  departmentId: string;
  departmentName: string;
  locationId: string;
  userTraits: [string];
  locationName: string;
  createdAt: string;
  groupId: string;
  groupName: string;
  clientTier: string;
  subdomain: string;
  praiseDirectory: {
    value: string;
  };
  timezone: string | undefined;
  abTestCohorts: [string];
  isLineManager: boolean;
}

export interface AuthError {
  error: Errors;
  status: AuthStatus.ERROR;
}

export interface AuthSuccess extends Required<AuthApiResponse> {
  status: AuthStatus.SUCCESS;
}

export interface AuthDegraded
  extends Omit<Required<AuthApiResponse>, 'iris_token'> {
  status: AuthStatus.DEGRADED;
}

export type AuthPayload = AuthError | AuthSuccess | AuthDegraded;

export const getAuthPayload = (payload: AuthApiResponse): AuthPayload => {
  if (payload !== undefined) {
    const {
      activationDate,
      id,
      token,
      iris_token,
      email,
      firstName,
      lastName,
      userType,
      permissions,
      departmentId,
      departmentName,
      locationId,
      userTraits,
      locationName,
      createdAt,
      groupId,
      groupName,
      clientTier,
      subdomain,
      praiseDirectory,
      timezone,
      abTestCohorts,
      isLineManager,
    } = payload;

    if (!isNil(id) && !isNil(token)) {
      if (!isNil(iris_token)) {
        return {
          activationDate,
          id,
          token,
          iris_token,
          status: AuthStatus.SUCCESS,
          email,
          firstName,
          lastName,
          userType,
          permissions,
          departmentId,
          departmentName,
          locationId,
          userTraits,
          locationName,
          createdAt,
          groupId,
          groupName,
          clientTier,
          subdomain,
          praiseDirectory,
          timezone,
          abTestCohorts,
          isLineManager,
        };
      }

      return {
        activationDate,
        id,
        token,
        email,
        firstName,
        lastName,
        userType,
        permissions,
        departmentId,
        departmentName,
        locationId,
        userTraits,
        locationName,
        createdAt,
        groupId,
        groupName,
        clientTier,
        subdomain,
        praiseDirectory,
        status: AuthStatus.DEGRADED,
        timezone,
        abTestCohorts,
        isLineManager,
      };
    }
  }

  return {
    error: Errors.ServerError,
    status: AuthStatus.ERROR,
  };
};

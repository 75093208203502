import React from 'react';
import { IconProps } from '../IconProps';

const SvgLockRounded = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <g
      clipPath="url(#LockRounded_svg__clip0_603_2292)"
      fillRule="evenodd"
      clipRule="evenodd"
      fill={primaryColor}
    >
      <path d="M2.75 11.25a2.5 2.5 0 0 1 2.5-2.5h13.5a2.5 2.5 0 0 1 2.5 2.5v10.5a2.5 2.5 0 0 1-2.5 2.5H5.25a2.5 2.5 0 0 1-2.5-2.5v-10.5zm2.5-.5a.5.5 0 0 0-.5.5v10.5a.5.5 0 0 0 .5.5h13.5a.5.5 0 0 0 .5-.5v-10.5a.5.5 0 0 0-.5-.5H5.25z" />
      <path d="M12 1.75A4.25 4.25 0 0 0 7.75 6v3.75a1 1 0 1 1-2 0V6a6.25 6.25 0 0 1 12.5 0v3.75a1 1 0 1 1-2 0V6A4.25 4.25 0 0 0 12 1.75zM12 16.75a.625.625 0 1 0 0-1.25v-.75a1.375 1.375 0 1 0 0 2.75v-.75z" />
      <path d="M12 14.75a1.375 1.375 0 1 1 0 2.75v-.75a.625.625 0 1 1 0-1.25v-.75z" />
    </g>
    <defs>
      <clipPath id="LockRounded_svg__clip0_603_2292">
        <path fill={primaryColor} d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgLockRounded;

import { practitionerMatchesQuery } from 'Services/BeGateway/Practitioner/__generated__/practitionerMatchesQuery';
import { PRACTITIONER_MATCHES_QUERY } from 'Services/BeGateway/Practitioner/practitioner.services';
import { useQuery } from '@apollo/client';
import { BEGatewayQueryContext, defaultQueryConfig } from 'utils/apollo';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';

export function useMatches() {
  const aiMatchingEnabled = useFeatureFlag(FEATURE_FLAGS.TALK_ENABLE_MATCHING);

  const {
    data: practitionerMatchesData,
    loading: practitionerMatchesLoading,
    error: practitionerMatchesError,
    refetch: refetchPractitionerMatches,
  } = useQuery<practitionerMatchesQuery>(PRACTITIONER_MATCHES_QUERY, {
    skip: !aiMatchingEnabled,
    ...defaultQueryConfig,
    ...BEGatewayQueryContext,
  });

  const practitionerMatches = (
    practitionerMatchesData?.practitionerMatches ?? []
  ).filter((match): match is NonNullable<typeof match> => match !== null);
  const userHasPractitionerMatches =
    !practitionerMatchesLoading && practitionerMatches.length > 0;

  return {
    userHasPractitionerMatches,
    practitionerMatches,
    practitionerMatchesLoading,
    practitionerMatchesError,
    refetchPractitionerMatches,
  };
}

import { useLazyQuery } from '@apollo/client';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import { rem } from 'polished';
import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { Link, useHistory } from 'react-router-dom';
import { css, styled, useTheme } from 'styles';
import { useLocation } from 'react-router';
import { isMSTeams } from 'utils/MSTeams';
import RoutePath from '../../../App/RoutePath';
import { tracking } from '../../../App/Tracking';
import FieldError from '../../../Shared/Form/FieldError';
import {
  FormikInput,
  validateFieldNotEmpty,
} from '../../../Shared/Form/Formik';
import PrimaryButton from '../../../Shared/PrimaryButton';
import {
  AuthWrapper,
  AuthWrapperBlock,
  AuthWrapperContent,
  AuthWrapperSubtitle,
  AuthWrapperTitle,
  createTextContentWidth,
} from '../../AuthWrapper';
import { medium } from '../../../utils';
import { AuthTypeEnum } from '../../../__generated__/globalTypes';
import BodyText from '../../../Shared/Typography/BodyText';
import { StyledForm, StyledFormFieldLabel } from './CommonFormStyledComponents';

const Form = styled(StyledForm)`
  // Target all browsers except ie
  @supports not (-ms-high-contrast: none) {
    ${medium(css`
      flex: 0;
    `)}
  }
`;

const SubmitButton = styled(PrimaryButton)`
  margin: auto 0 ${rem('16px')} 0;
  width: 100%;

  ${medium(css`
    margin-top: ${rem(24)};
  `)}
`;

const HelpText = styled(BodyText)`
  margin-top: ${rem(18)};
`;

const PlusOneTextWrapper = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  forwardedAs: 'div',
}))`
  margin-top: ${rem(24)};
  ${createTextContentWidth()};
`;

const PlusOneText = styled(BodyText)`
  display: inline;
`;
interface FormFields {
  companyName: string;
}

export const FIND_SUBDOMAIN_QUERY = gql`
  query findOrganisationByAlias($input: OrganisationAliasSearchInput!) {
    findOrganisationByAlias(input: $input) {
      code
      success
      organisation {
        subdomain
      }
      authType
    }
  }
`;

const CompanyNameForm = () => {
  const { t: translate } = useTranslation<Namespace<'logged_out' | 'shared'>>([
    'logged_out',
    'shared',
  ]);
  const { typography } = useTheme();
  const formikRef = useRef<Formik<FormFields>>(null);
  const [error, setError] = useState('');
  const location = useLocation();
  const history = useHistory();
  const redirectLocation = new URLSearchParams(location.search).get('redirect');
  const isSignUpFlow =
    redirectLocation && redirectLocation.indexOf('signup') > 0;
  const helpLink = isSignUpFlow
    ? `${RoutePath.FindYourOrganisation}?path=signup`
    : RoutePath.FindYourOrganisation;

  if (isMSTeams()) {
    const shouldRemoveSubdomain = new URLSearchParams(location.search).get(
      'removeSubdomain',
    );

    if (shouldRemoveSubdomain) {
      localStorage.removeItem('subdomain');
    }
  }

  const [findSubdomain, { loading }] = useLazyQuery(FIND_SUBDOMAIN_QUERY, {
    onCompleted: data => {
      const {
        findOrganisationByAlias: { organisation, success, authType },
      } = data;

      if (!success) {
        tracking.track('find-organisation-failed', {
          subdomain: formikRef?.current?.state.values.companyName,
        });

        setError(
          translate(
            'logged_out:login.forms.company_name.form.errors.org_not_found',
          ),
        );
      } else {
        tracking.track('find-organisation-confirmed', {
          subdomain: organisation.subdomain,
        });

        let redirectPath;

        if (isMSTeams()) {
          localStorage.setItem('subdomain', organisation.subdomain);
        }

        // if using SSO, redirect to root page
        if (authType === AuthTypeEnum.AUTH0_SSO) {
          // subdomain root (defaults to signin)
          redirectPath = isMSTeams() ? RoutePath.LoginWithSSO : RoutePath.Login;
        } else if (authType === AuthTypeEnum.MIXED_MODE) {
          redirectPath = isSignUpFlow
            ? // if on sign up flow, pass that as query param
              `${RoutePath.AuthMethod}?redirect=/signup`
            : // else just redirect to mixed mode page
              RoutePath.AuthMethod;
        } else {
          // for legacy credentials go to appropriate redirect URL (signup/signin)
          redirectPath = redirectLocation || RoutePath.LoginWithCredentials;
        }

        if (isMSTeams()) {
          history.push(`${redirectPath}`);
        } else {
          window.location.assign(
            `${window.location.protocol}//${organisation.subdomain}.${process.env.REACT_APP_DOMAIN}${redirectPath}`,
          );
        }
      }
    },
    onError: () => {
      setError(translate('shared:errors.messages.something_wrong'));
    },
  });

  const onSubmit = (values: FormFields) => {
    findSubdomain({
      variables: {
        input: {
          searchTerm: values.companyName,
        },
      },
    });
  };

  return (
    <AuthWrapper>
      <AuthWrapperBlock data-testid="company-name-form">
        <AuthWrapperTitle data-testid="form-title">
          {translate('logged_out:login.forms.company_name.heading')}
        </AuthWrapperTitle>
        <AuthWrapperSubtitle data-testid="form-subtitle">
          {translate('logged_out:login.forms.company_name.subtitle')}
        </AuthWrapperSubtitle>
        <AuthWrapperContent>
          <Formik
            initialValues={{ companyName: '' }}
            onSubmit={onSubmit}
            ref={formikRef}
          >
            {({ isValid }) => (
              <Form>
                <StyledFormFieldLabel
                  data-testid="company-name-input-label"
                  htmlFor="companyName"
                >
                  {translate(
                    'logged_out:login.forms.company_name.form.company_name_field.label',
                  )}
                </StyledFormFieldLabel>
                <FormikInput
                  data-testid="company-name-input"
                  name="companyName"
                  placeholder={translate(
                    'logged_out:login.forms.company_name.form.company_name_field.label',
                  )}
                  aria-label={translate(
                    'logged_out:login.forms.company_name.form.company_name_field.a11y_label',
                  )}
                  validate={value =>
                    validateFieldNotEmpty(
                      value,
                      translate(
                        'logged_out:login.forms.company_name.form.errors.empty_company_name',
                      ),
                    )
                  }
                  onFocus={() => setError('')}
                  expandHeight={false}
                />
                {error ? <FieldError message={error} /> : null}
                <SubmitButton
                  data-testid="submit-button"
                  type="submit"
                  label={translate(
                    'logged_out:login.forms.company_name.form.submit_button.label',
                  )}
                  disabled={!isValid}
                  loading={loading}
                />
              </Form>
            )}
          </Formik>
          <HelpText sizes={[typography.fontSizes.fontSize16]}>
            {Trans({
              t: translate,
              i18nKey: 'logged_out:login.forms.company_name.footer_help_text',
              defaults:
                "Can't find your company? <help_link>Let us help</help_link>",
              components: {
                help_link: (
                  <Link
                    data-testid="find-company-help-link"
                    to={helpLink}
                    onClick={() => {
                      tracking.track('search-organisation');
                    }}
                  />
                ),
              },
            })}
          </HelpText>
          {!isSignUpFlow ? (
            <PlusOneTextWrapper data-testid="plus-one-text">
              {Trans({
                t: translate,
                i18nKey:
                  'logged_out:login.forms.company_name.plus_one_footer_help_text',
                defaults:
                  '<bold>Been given guest access to Unmind by a friend or family member?</bold> <colour>Use their company name.</colour>.',
                components: {
                  bold: (
                    <PlusOneText
                      sizes={[typography.fontSizes.fontSize16]}
                      weight={typography.fontWeights.bold}
                    />
                  ),
                  colour: (
                    <PlusOneText sizes={[typography.fontSizes.fontSize16]} />
                  ),
                },
              })}
            </PlusOneTextWrapper>
          ) : null}
        </AuthWrapperContent>
      </AuthWrapperBlock>
    </AuthWrapper>
  );
};

export default CompanyNameForm;

import { RouteProps } from 'react-router';
import { TalkHomePage } from 'Talk/pages';
import { InviteesLimitReached } from 'LoggedOut/SignUp/ErrorScreens/InviteesLimitReached';
import { TalkBrowseIndex } from 'Talk/pages/TalkBrowseIndex';
import CheatSheetPage from 'CheatSheets/CheatSheetPage';
import { MSTeamsSSO } from 'LoggedOut/MSTeams/MSTeamsSSO';
import MSTeamsSSOCallback from 'LoggedOut/MSTeams/MSTeamsSSOCallback';
import { ChatbotLandingPage, ChatbotPage } from 'Assistant/Chatbot';
import { AlternativeStartPagePlayer } from 'LoggedOut/Start/AlternativeMSTeamsStartPage/AlternativeStartPagePlayer';
import { Compass } from 'Compass/Compass';
import { CompassOnboardingPage } from 'Compass/CompassOnboardingPage';
import CompassCheckIn from 'Compass/CompassCheckIn';
import { ValueOfCompassPage } from 'Compass/CompassFurtherInfoPages/ValueOfCompassPage';
import { FinancialImpactCalculatorPage } from 'Compass/CompassFurtherInfoPages/FinancialImpactCalculatorPage';
import { BenchmarkInfoPage } from 'Compass/CompassFurtherInfoPages/BenchmarkInfoPage';
import { PersonalisedTodayScreen } from '../Home';
import LoginViaIDPInitiatedSSO from '../LoggedOut/Login/LoginViaIDPInitiatedSSO';
import i18n from '../i18n/config';
import { Account } from '../Account';
import PlusOneSignUp from '../Account/PlusOne/PlusOneSignUp';
import Admin from '../Admin';
import Dashboard from '../Dashboard';
import CategoryPage from '../Explore/Category/CategoryPage';
import ExplorePage from '../Explore/ExplorePage';
import YourCoursesPage from '../Explore/YourCourses/YourCoursesPage';
import { HelpRoot, HelpView, SosRedirect } from '../Help';
import AuthMethod from '../LoggedOut/AuthMethod/AuthMethod';
import FindOrganisationConfirmation from '../LoggedOut/FindOrganisationConfirmation/FindOrganisationConfirmation';
import Login from '../LoggedOut/Login/Login';
import LoginViaSSO from '../LoggedOut/Login/LoginViaSSO';
import LoginViaUsernamePassword from '../LoggedOut/Login/LoginViaUsernamePassword';
import MagicLogin from '../LoggedOut/MagicLogin/MagicLogin';
import MagicLoginConfirmation from '../LoggedOut/MagicLogin/MagicLoginConfirmation';
import ResetPassword from '../LoggedOut/ResetPassword/ResetPassword';
import ResetPasswordEmailConfirmation from '../LoggedOut/ResetPassword/ResetPasswordEmailConfirmation';
import CompanyNameForm from '../LoggedOut/SignUp/Forms/CompanyNameForm';
import FindOrganisationForm from '../LoggedOut/SignUp/Forms/FindOrganisationForm';
import ForgotPasswordForm from '../LoggedOut/SignUp/Forms/ForgotPasswordForm';
import { Privacy } from '../LoggedOut/SignUp/Privacy';
import SSOSignUp from '../LoggedOut/SignUp/SSOSignUp';
import SignUp from '../LoggedOut/SignUp/SignUp';
import Tool from '../Tools/Tool';
import Track from '../Track/Track';
import UnmindIndex from '../Track/Wellbeing';
import VerifyAccountOwnership from '../LoggedOut/VerifyAccountOwnership/VerifyAccountOwnership';
import {
  VoucherCodeConfirmationPage,
  VoucherCodeFormPage,
} from '../LoggedOut/SignUp/VoucherCode';
import WelcomeToUnmind from '../LoggedOut/SignUp/WelcomeToUnmind';
import CongratulationsSeries from '../Series/SingleSeries/SeriesDay/CongratulationsSeries';
import SeriesDayReminder from '../Series/SingleSeries/SeriesDay/Reminder';
import SeriesDay from '../Series/SingleSeries/SeriesDay/index';
import SeriesOverview from '../Series/SingleSeries/SeriesOverview';
import { DailyBoostRedirect } from '../Tools/DailyBoostRedirect';
import { MoodTracker } from '../Track/Moods/MoodTracker';
import ConfirmSSOAccount from '../LoggedOut/ConfirmSSOAccount/ConfirmSSOAccount';
import { Start } from '../LoggedOut/Start/Start';
import SSOLoginHome from '../LoggedOut/UniversalLogin/SSOLoginHome';
import VerifyAccountOwnershipEmailConfirmation from '../LoggedOut/VerifyAccountOwnership/VerifyAccountOwnershipEmailConfirmation';
import { InvalidInviteToken } from '../LoggedOut/SignUp/ErrorScreens/InvalidInviteToken';
import AdditionalInformationNeeded from './Auth/AdditionalInformationNeeded/AdditionalInformationNeeded';
import ExplicitPrivacyConsentNeeded from './Auth/ExplicitPrivacyConsentNeeded/ExplicitPrivacyConsentNeeded';
import SSOLoginSuccess from './Auth/SSOLoginSuccess/SSOLoginSuccess';
import Permissions from './Auth/permissions';
import RoutePath from './RoutePath';
import { TrackingConfig } from './Tracking';

export interface RouteConfig extends RouteProps {
  permissions?: Permissions[];
  redirectIfAuthenticated?: string;
  routes?: RouteConfig[];
  trackingConfig?: TrackingConfig;
  title: string;
}

const defaultPermissions = [
  Permissions.user,
  Permissions.admin,
  Permissions.superAdmin,
];

function createAuthenticatedRoute({
  exact = true,
  permissions = defaultPermissions,
  ...routeConfig
}: RouteConfig): RouteConfig {
  return {
    exact,
    permissions,
    ...routeConfig,
  };
}

export const chatbotLandingRoute = createAuthenticatedRoute({
  component: ChatbotLandingPage,
  path: RoutePath.ChatbotLanding,
  title: i18n.t('chatbot:title'),
});

export const chatbotRoute = createAuthenticatedRoute({
  component: ChatbotPage,
  path: RoutePath.Chatbot,
  title: i18n.t('chatbot:title'),
});

export const checkInRoute = createAuthenticatedRoute({
  component: MoodTracker,
  path: RoutePath.CheckIn,
  title: 'Mood Tracker',
});

export const seriesSessionRoute = createAuthenticatedRoute({
  component: SeriesDay,
  path: `${RoutePath.Series}/:slug/day/:day(\\d+)/:segment(\\d+)?`,
  title: 'Series',
});

export const toolPlayerRoute = createAuthenticatedRoute({
  component: Tool,
  exact: false, // Just in case anyone still has bookmarks to the defunct /transcript route
  path: RoutePath.Tool,
  title: 'Tools',
});

export const dailyBoostPlayerRoute = createAuthenticatedRoute({
  component: DailyBoostRedirect,
  exact: true,
  path: [`${RoutePath.Shorts}/daily-boost`],
  title: 'Daily Boost',
});

export const shortPlayerRoute = createAuthenticatedRoute({
  component: Tool,
  exact: true,
  path: [`${RoutePath.Shorts}/daily-boost/:tool`, `${RoutePath.Shorts}/:tool`],
  title: 'Shorts',
});

export const unmindIndexRoute = createAuthenticatedRoute({
  component: UnmindIndex,
  path: RoutePath.UnmindIndex,
  title: 'Wellbeing Tracker',
});

const trackRoute = createAuthenticatedRoute({
  component: Track,
  path: RoutePath.Track,
  title: 'Track',
});

const compassRoute = createAuthenticatedRoute({
  component: Compass,
  exact: true,
  path: RoutePath.Compass,
  title: i18n.t('compass:title'),
});

const compassOnboardingRoute = createAuthenticatedRoute({
  component: CompassOnboardingPage,
  exact: true,
  path: RoutePath.CompassOnboarding,
  title: i18n.t('compass:onboarding_title'),
});

const valueOfCompassRoute = createAuthenticatedRoute({
  component: ValueOfCompassPage,
  exact: true,
  path: RoutePath.ValueOfCompass,
  title: i18n.t('compass:value_of_compass_title'),
});

const compassCalculatorRoute = createAuthenticatedRoute({
  component: FinancialImpactCalculatorPage,
  exact: true,
  path: RoutePath.CompassCalculator,
  title: i18n.t('compass:financial_impact_title'),
});

const compassBenchmarkingRoute = createAuthenticatedRoute({
  component: BenchmarkInfoPage,
  exact: true,
  path: RoutePath.CompassBenchmarking,
  title: i18n.t('compass:benchmark_info_title'),
});

const routes: RouteConfig[] = [
  chatbotLandingRoute,
  chatbotRoute,
  checkInRoute,
  {
    component: CompanyNameForm,
    exact: true,
    path: RoutePath.SignInToYourOrganisation,
    redirectIfAuthenticated: RoutePath.Home,
    title: i18n.t('logged_out:login.forms.company_name.title'),
  },
  {
    component: LoginViaUsernamePassword,
    exact: true,
    path: RoutePath.LoginWithCredentials,
    redirectIfAuthenticated: RoutePath.Home,
    title: i18n.t('logged_out:login.title'),
  },
  {
    component: LoginViaSSO,
    exact: true,
    path: RoutePath.LoginWithSSO,
    redirectIfAuthenticated: RoutePath.Home,
    title: i18n.t('logged_out:login.title'),
  },
  {
    component: Login,
    exact: true,
    path: '/',
    redirectIfAuthenticated: RoutePath.Home,
    title: i18n.t('logged_out:login.title'),
  },
  {
    component: MagicLogin,
    exact: true,
    path: RoutePath.MagicLogin,
    redirectIfAuthenticated: '/',
    title: i18n.t('logged_out:login.forms.magic_login.title'),
  },
  {
    component: MagicLoginConfirmation,
    exact: true,
    path: RoutePath.MagicLoginConfirmation,
    redirectIfAuthenticated: '/',
    title: i18n.t('logged_out:login.magic_login_confirmation.title'),
  },
  {
    component: ForgotPasswordForm,
    exact: true,
    path: RoutePath.ForgotPassword,
    redirectIfAuthenticated: RoutePath.Home,
    title: i18n.t('logged_out:login.forms.forgot_password.title'),
  },
  {
    component: InvalidInviteToken,
    exact: true,
    path: RoutePath.InvalidInviteToken,
    redirectIfAuthenticated: RoutePath.Home,
    title: i18n.t(
      'logged_out:sign_up.invalid_token.generic_error_screen.title',
    ),
  },
  {
    component: InviteesLimitReached,
    exact: true,
    path: RoutePath.InviteesLimitReached,
    redirectIfAuthenticated: RoutePath.Home,
    title: i18n.t(
      'logged_out:sign_up.invalid_token.invitees_limit_reached.title',
    ),
  },
  {
    component: ResetPasswordEmailConfirmation,
    exact: true,
    path: RoutePath.ForgotPasswordEmailConfirmation,
    redirectIfAuthenticated: RoutePath.Home,
    title: i18n.t('logged_out:login.reset_password_email_confirmation.title'),
  },
  {
    component: WelcomeToUnmind,
    exact: true,
    path: RoutePath.WelcomeToUnmind,
    title: i18n.t('logged_out:sign_up.welcome_screen.title'),
  },
  {
    component: ResetPassword,
    exact: true,
    path: RoutePath.ResetPassword,
    redirectIfAuthenticated: RoutePath.Home,
    title: i18n.t('logged_out:login.forms.reset_password.title'),
  },
  {
    component: FindOrganisationForm,
    exact: true,
    path: RoutePath.FindYourOrganisation,
    redirectIfAuthenticated: RoutePath.Home,
    title: i18n.t('logged_out:sign_up.forms.find_organisation.title'),
  },
  {
    component: Privacy,
    exact: true,
    path: RoutePath.SignUpPrivacy,
    redirectIfAuthenticated: RoutePath.Home,
    title: i18n.t('logged_out:sign_up.privacy.title'),
  },
  {
    component: Start,
    exact: true,
    path: RoutePath.StartSplitScreen,
    redirectIfAuthenticated: RoutePath.Home,
    title: i18n.t('logged_out:getting_started.title'),
  },
  {
    component: AlternativeStartPagePlayer,
    exact: true,
    path: RoutePath.AlternativeStartPagePlayer,
    redirectIfAuthenticated: RoutePath.Home,
    title: i18n.t('logged_out:ms_teams_alternative_start.player_title'),
  },
  {
    component: AuthMethod,
    exact: true,
    path: RoutePath.AuthMethod,
    redirectIfAuthenticated: RoutePath.Home,
    title: i18n.t('logged_out:auth_method.title'),
  },
  {
    // More subroutes defined in LoggedOut/SignUp/SignUp.tsx
    component: SignUp,
    exact: false,
    path: RoutePath.SignUp,
    redirectIfAuthenticated: RoutePath.Home,
    title: i18n.t('logged_out:sign_up.title'),
  },
  {
    component: PlusOneSignUp,
    exact: true,
    path: RoutePath.PlusOneSignUp,
    redirectIfAuthenticated: RoutePath.Home,
    title: i18n.t('logged_out:sign_up.title'),
  },
  {
    component: FindOrganisationConfirmation,
    exact: true,
    path: RoutePath.FindYourOrganisationConfirmation,
    redirectIfAuthenticated: RoutePath.Home,
    title: i18n.t('logged_out:sign_up.voucher_code_confirmation.title'),
  },
  {
    component: SSOLoginHome,
    exact: true,
    path: RoutePath.SSOLoginHome,
    title: i18n.t('logged_out:login.title'),
  },
  {
    component: SSOLoginSuccess,
    exact: true,
    path: RoutePath.SSOLoginSuccess,
    permissions: [Permissions.user, Permissions.admin, Permissions.superAdmin],
    title: i18n.t('logged_out:login.title'),
  },
  {
    component: PersonalisedTodayScreen,
    exact: true,
    path: RoutePath.Home,
    permissions: [Permissions.user, Permissions.admin, Permissions.superAdmin],
    title: 'Home',
  },
  {
    component: ExplorePage,
    exact: true,
    path: RoutePath.Explore,
    permissions: [Permissions.user, Permissions.admin, Permissions.superAdmin],
    title: 'Explore',
  },
  {
    component: CategoryPage,
    exact: true,
    path: `${RoutePath.Explore}/:category`,
    permissions: [Permissions.user, Permissions.admin, Permissions.superAdmin],
    title: 'Category',
  },
  {
    component: CheatSheetPage,
    exact: true,
    path: `${RoutePath.CheatSheet}/:slug`,
    permissions: [Permissions.user, Permissions.admin, Permissions.superAdmin],
    title: i18n.t('cheat_sheets:title'),
  },
  {
    component: Account,
    exact: true,
    path: RoutePath.Account,
    permissions: [Permissions.user, Permissions.admin, Permissions.superAdmin],
    title: 'Account',
  },
  {
    component: Admin,
    exact: true,
    path: RoutePath.Admin,
    permissions: [Permissions.admin, Permissions.superAdmin],
    title: 'Admin',
  },
  {
    component: SeriesOverview,
    exact: true,
    path: `${RoutePath.Series}/:slug`,
    permissions: [Permissions.user, Permissions.admin, Permissions.superAdmin],
    title: 'Series',
  },
  {
    component: SeriesOverview,
    exact: true,
    path: `${RoutePath.Courses}/:slug`,
    permissions: [Permissions.user, Permissions.admin, Permissions.superAdmin],
    title: 'Courses',
  },
  seriesSessionRoute,
  {
    component: CongratulationsSeries,
    exact: true,
    path: `${RoutePath.Series}/:slug/completed`,
    permissions: [Permissions.user, Permissions.admin, Permissions.superAdmin],
    title: 'Completed Series',
  },
  {
    component: SeriesDayReminder,
    exact: true,
    path: `${RoutePath.Series}/:slug/reminder`,
    permissions: [Permissions.user, Permissions.admin, Permissions.superAdmin],
    title: 'Series',
  },
  compassRoute,
  compassOnboardingRoute,
  valueOfCompassRoute,
  compassCalculatorRoute,
  compassBenchmarkingRoute,
  unmindIndexRoute,
  {
    component: TalkHomePage,
    exact: true,
    path: RoutePath.Talk,
    permissions: [Permissions.user, Permissions.admin, Permissions.superAdmin],
    title: 'Unmind Talk',
  },
  {
    component: TalkBrowseIndex,
    exact: true,
    path: [`${RoutePath.TalkBrowse}/:practitionerId`, RoutePath.TalkBrowse],
    permissions: [Permissions.user, Permissions.admin, Permissions.superAdmin],
    title: 'Unmind Talk',
  },
  toolPlayerRoute,
  dailyBoostPlayerRoute,
  shortPlayerRoute,
  trackRoute,
  {
    component: HelpRoot,
    exact: true,
    path: `${RoutePath.Help}`,
    permissions: [Permissions.user, Permissions.admin, Permissions.superAdmin],
    title: 'Help',
  },
  {
    component: HelpView,
    exact: true,
    path: `${RoutePath.Help}/:id`,
    permissions: [Permissions.user, Permissions.admin, Permissions.superAdmin],
    title: 'Help',
  },
  {
    component: SosRedirect,
    exact: true,
    path: RoutePath.Sos,
    permissions: [Permissions.user, Permissions.admin, Permissions.superAdmin],
    title: 'Help',
  },
  {
    component: VoucherCodeFormPage,
    exact: true,
    path: RoutePath.VoucherCodeSignUp,
    redirectIfAuthenticated: RoutePath.Home,
    title: i18n.t('logged_out:sign_up.title'),
  },
  {
    component: VoucherCodeConfirmationPage,
    exact: true,
    path: RoutePath.VoucherCodeSignUpConfirmation,
    redirectIfAuthenticated: RoutePath.Home,
    title: i18n.t('logged_out:sign_up.title'),
  },
  {
    component: AdditionalInformationNeeded,
    exact: true,
    path: RoutePath.AdditionalInformationNeeded,
    title: 'Additional Information Needed',
  },
  {
    component: ExplicitPrivacyConsentNeeded,
    exact: true,
    path: RoutePath.ExplicitPrivacyConsentNeeded,
    title: 'Explicit Privacy Consent Needed',
  },
  {
    component: Dashboard,
    path: RoutePath.Dashboard,
    permissions: [Permissions.admin, Permissions.superAdmin],
    title: 'Dashboard',
  },
  {
    component: CompassCheckIn,
    path: RoutePath.CompassCheckIn,
    title: 'Compass Index',
  },
  {
    // ToDo: Redirect if authenticated
    component: VerifyAccountOwnership,
    path: RoutePath.VerifyAccountOwnership,
    title: i18n.t('logged_out:verify_account_ownership.title'),
  },
  {
    // ToDo: Redirect if authenticated
    component: VerifyAccountOwnershipEmailConfirmation,
    path: RoutePath.VerifyAccountOwnershipEmailConfirmation,
    title: i18n.t(
      'logged_out:verify_account_ownership_email_confirmation.title',
    ),
  },
  {
    // ToDo: Redirect if authenticated
    component: SSOSignUp,
    path: RoutePath.SSOSignUp,
    title: 'SSO Sign up',
  },
  {
    component: ConfirmSSOAccount,
    path: RoutePath.ConfirmSSOAccount,
    redirectIfAuthenticated: RoutePath.Home,
    title: 'Confirm Account',
  },
  {
    component: LoginViaIDPInitiatedSSO,
    path: RoutePath.SSOIdpInitatedLogin,
    redirectIfAuthenticated: RoutePath.Home,
    title: 'IDP Initiated SSO',
  },
  {
    component: YourCoursesPage,
    exact: true,
    path: RoutePath.YourCourses,
    permissions: [Permissions.user, Permissions.admin, Permissions.superAdmin],
    title: 'Your Courses',
  },
  {
    component: MSTeamsSSO,
    exact: true,
    path: RoutePath.MSTeamsSSO,
    title: 'MSTeams SSO',
  },
  {
    component: MSTeamsSSOCallback,
    exact: true,
    path: RoutePath.MSTeamsSSOCallback,
    title: 'MSTeams SSO Callback',
  },
];

export default routes;

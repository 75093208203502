import {
  ApolloError,
  ApolloQueryResult,
  FetchResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { Alert } from '@unmind/design-system-components-web';
import { logException } from 'App/logging';
import RoutePath from 'App/RoutePath';
import { tracking } from 'App/Tracking';
import { useTrackServerside } from 'App/Tracking/serverside';
import { endOfMonth, isValid, startOfMonth } from 'date-fns';
import { Formik, FormikActions } from 'formik';
import { Close } from 'icons';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { useHistory } from 'react-router-dom';
import { PRACTITIONER_REQUEST_MUTATION } from 'Services/BeGateway/Practitioner/practitioner.services';
import { practitionerQuery_practitioner as Practitioner } from 'Services/BeGateway/Practitioner/__generated__/practitionerQuery';
import { practitionerRequestMutation as PractitionerMutation } from 'Services/BeGateway/Practitioner/__generated__/practitionerRequestMutation';
import {
  TALK_EVENTS_QUERY,
  TALK_EVENT_REQUESTS_QUERY,
} from 'Services/BeGateway/Talk/talk.services';
import { talkEventRequestsQuery_unmindEventRequests_edges_node as EventRequest } from 'Services/BeGateway/Talk/__generated__/talkEventRequestsQuery';
import { talkEventsQuery_unmindEvents_edges_node as Event } from 'Services/BeGateway/Talk/__generated__/talkEventsQuery';
import { userCountryStateQuery_user as UserCountryState } from 'Services/User/__generated__/userCountryStateQuery';
import { AssessmentStatus } from 'Services/BeGateway/__generated__/beGatewayTypes';
import { useAssignFocus, WrappingButton } from 'Shared/Accessibility';
import LoadingIndicator from 'Shared/LoadingIndicator';
import { Drawer } from 'Talk/components/Drawer';
import { useTalkUserDetails, useTimeout, useToday } from 'Talk/hooks';
import { TimeSlot } from 'Talk/lib/availability';
import { BEGatewayQueryContext } from 'utils/apollo';
import * as Yup from 'yup';
import { BookingStageConfirm } from './components/BookingStageConfirm';
import { BookingStageDateSelect } from './components/BookingStageDateSelect';
import { ConfirmFooter } from './components/ConfirmFooter';
import {
  BookingAlertContainer,
  BookingFlowContainer,
  ContentContainer,
  Header,
  PractitionerTitleText,
  StyledIcon,
} from './styles';
import {
  BookingStage,
  BookingType,
  FieldValues,
  FormikStatus,
  SubmissionError,
  FormValues,
} from './types';

const formValidationSchema = Yup.object().shape({
  [FieldValues.BookingType]: Yup.string(),
  [FieldValues.Message]: Yup.string()
    .notRequired()
    .max(1000, 'Message must be at most 1000 characters'),
  [FieldValues.SelectedSlot]: Yup.object({
    start: Yup.string(),
    end: Yup.string(),
  }).when(FieldValues.BookingType, {
    is: BookingType.DirectBooking,
    then: () =>
      Yup.object({
        start: Yup.string().required(),
        end: Yup.string().required(),
      }).required(),
  }),
});

const generateDemoState = (
  practitionerName: string,
  practitioner: Practitioner,
  selectedSlot: TimeSlot | undefined,
  isDirectBookingEnabled: boolean,
): { event: Event } | { eventRequest: EventRequest } => {
  if (!!isDirectBookingEnabled) {
    return {
      event: {
        __typename: 'Event',
        id: 'event-24',
        name: practitionerName,
        accountId: practitioner.account.id,
        beginsAt: selectedSlot?.start,
        endsAt: selectedSlot?.end,
        description: '',
        icalendar: null,
        location: '',
        comment: '',
        session: null,
        zoomMeetingDetails: null,
        recurring: false,
        timeZone: 'Europe/Dublin',
        type: 'Client',
        source: 'unmind',
        createdBy: practitioner.id,
        status: 'published',
        userProfile: {
          __typename: 'UserProfile',
          id: practitioner.id,
          firstName: practitioner.firstName,
          lastName: practitioner.lastName,
          photoUrls: practitioner.photoUrls,
          phone: '',
        },
        eventGuests: [
          {
            __typename: 'EventGuest',
            id: 'eventGuest-24',
            externalRef: '2IQyMGJi1FSgC5',
            assessmentStatus: AssessmentStatus.not_needed,
            contact: {
              __typename: 'Contact',
              id: 'contact-1',
              unmindUserId: '1',
            },
          },
        ],
      },
    };
  } else {
    return {
      eventRequest: {
        __typename: 'EventRequest',
        id: 'eventRequest-24',
        accountId: practitioner.account.id,
        preferredPractitioner: {
          __typename: 'UserProfile',
          id: practitioner.id,
          firstName: practitioner.firstName,
          lastName: practitioner.lastName,
          photoUrls: practitioner.photoUrls,
          timeZone: practitioner.timeZone,
        },
      },
    };
  }
};

const configureInitialFormValues = (
  practitioner: Practitioner,
  today: Date,
  isDirectBookingEnabled: boolean,
) => {
  const firstAvailableSlot = practitioner.availability?.times?.[0];
  const lastAvailableSlot = practitioner.availability?.times?.slice(-1)[0];
  const from = new Date(firstAvailableSlot?.start);
  const to = new Date(lastAvailableSlot?.start);

  const range = {
    from: isValid(from) ? from : startOfMonth(today),
    to: isValid(to) ? to : endOfMonth(today),
  };

  const initialFormValues: FormValues = {
    bookingType: isDirectBookingEnabled
      ? BookingType.DirectBooking
      : BookingType.BookingRequest,
    bookingStage: isDirectBookingEnabled
      ? BookingStage.DateSelect
      : BookingStage.Confirm,
    range,
    selectedDate: firstAvailableSlot
      ? new Date(firstAvailableSlot.start)
      : undefined,
    selectedSlot: undefined,
  };

  return initialFormValues;
};

const submitEvent = async ({
  setStatus,
  user,
  practitioner,
  selectedSlot,
  message,
  sendPractitionerRequest,
  submissionStatus,
}: {
  setStatus(status?: FormikStatus): void;
  user: ReturnType<typeof useTalkUserDetails>['user'];
  practitioner: Practitioner;
  message: string | undefined;
  selectedSlot: TimeSlot | undefined;
  submissionStatus: FormikStatus;
  sendPractitionerRequest(
    variables: OperationVariables,
  ): Promise<FetchResult<ApolloQueryResult<PractitionerMutation>>>;
}) => {
  setStatus({});

  if (!user.isDemo) {
    await sendPractitionerRequest({
      variables: {
        input: {
          practitionerId: practitioner.id,
          practitionerAccountId: practitioner.account.id,
          practitionerFirstName: practitioner.firstName,
          practitionerLastName: practitioner.lastName,
          message,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          start: selectedSlot?.start,
          end: selectedSlot?.end,
        },
      },
    });
  }

  submissionStatus.submissionComplete = true;
  setStatus(submissionStatus);
};

export interface PractitionerDrawerProps {
  practitioner: Practitioner;
  userCountryState: UserCountryState;
  setIsOpen(state: boolean): void;
  isOpen: boolean;
  hasBookingOrRequestWithAnotherPractitioner: boolean;
  source?: string;
  practitionerIsMatch?: boolean;
}

export const PractitionerDrawer = ({
  practitioner,
  setIsOpen,
  isOpen,
  hasBookingOrRequestWithAnotherPractitioner,
  source,
  practitionerIsMatch,
}: PractitionerDrawerProps) => {
  const { t } = useTranslation<Namespace<'talk'>>('talk');
  const { timeout } = useTimeout();
  const { loading, user } = useTalkUserDetails();
  const history = useHistory();
  const trackServerside = useTrackServerside();
  const today = useToday();

  const titleRef = useRef<HTMLDivElement>(null);
  useAssignFocus(titleRef, isOpen);

  const practitionerName = `${practitioner.firstName} ${practitioner.lastName}`;
  const isDirectBookingEnabled = !!practitioner.careUseAvailability;

  const [practitionerRequestMutation, { loading: mutationLoading }] =
    useMutation(PRACTITIONER_REQUEST_MUTATION, {
      refetchQueries: [TALK_EVENTS_QUERY, TALK_EVENT_REQUESTS_QUERY],
      ...BEGatewayQueryContext,
    });

  const trackBookingEvent = useCallback(
    async (formValues: FormValues) => {
      const bookingType: BookingType = formValues.selectedSlot?.start
        ? BookingType.DirectBooking
        : BookingType.BookingRequest;

      tracking.track('talk-practitioner-confirm-booking-selected', {
        bookingType,
        bookingSource: source ?? 'direct_link',
        practitionerIsMatch: practitionerIsMatch ?? false,
        practitionerId: practitioner.id,
        hasMessage: !!formValues.message,
      });

      return trackServerside({
        eventName: 'TALK_BOOKING_FLOW_COMPLETED',
        eventProperties: { bookingType },
        brazeProperties: { bookingType },
      });
    },
    [trackServerside, practitioner.id, source, practitionerIsMatch],
  );

  const onSubmit = useCallback(
    async (
      formValues: FormValues,
      {
        resetForm,
        setSubmitting,
        setStatus,
      }: Pick<
        FormikActions<FormValues>,
        'resetForm' | 'setSubmitting' | 'setStatus'
      >,
    ) => {
      if (mutationLoading) return null;
      const sendPractitionerRequest = async (variables: OperationVariables) =>
        practitionerRequestMutation(variables);

      const { message, selectedSlot } = formValues;
      const submissionStatus: FormikStatus = {};

      try {
        await submitEvent({
          setStatus,
          user,
          practitioner,
          message,
          selectedSlot,
          submissionStatus,
          sendPractitionerRequest,
        });

        if (!user.isDemo) {
          await trackBookingEvent(formValues);
        }

        const state = !!user.isDemo
          ? generateDemoState(
              practitionerName,
              practitioner,
              selectedSlot,
              isDirectBookingEnabled,
            )
          : undefined;

        timeout(() => {
          setIsOpen(false);
          resetForm();
          history.push(RoutePath.Talk, state);
        }, 2000);
      } catch (e: unknown) {
        let errorMessage: string;

        if (e instanceof ApolloError && typeof e.message === 'string') {
          errorMessage = e.message.includes('has a session booked')
            ? t('booking_flow.booking_error_session_exists')
            : t('booking_flow.booking_error');
        } else {
          errorMessage = t('booking_flow.booking_error');
        }

        const error: SubmissionError = {
          error: true,
          message: errorMessage,
        };

        submissionStatus.submissionError = error;
        setStatus(submissionStatus);
        logException(e);
      } finally {
        setSubmitting(false);
      }
    },
    [
      mutationLoading,
      practitionerRequestMutation,
      user,
      practitioner,
      practitionerName,
      isDirectBookingEnabled,
      timeout,
      trackBookingEvent,
      setIsOpen,
      history,
      t,
    ],
  );

  const titleText = t(
    isDirectBookingEnabled
      ? 'booking_flow.book_session_header'
      : 'booking_flow.request_session_header',
    {
      firstName: practitioner.firstName,
    },
  );

  const initialFormValues: FormValues = configureInitialFormValues(
    practitioner,
    today,
    isDirectBookingEnabled,
  );

  const handleCloseDrawer = useCallback(() => {
    setIsOpen(false);
    if (window.zE) window?.zE('messenger', 'show');
  }, [setIsOpen]);

  if (!user || loading) {
    return <LoadingIndicator />;
  }

  return (
    <Drawer setIsOpen={handleCloseDrawer} isOpen={isOpen} side="right">
      {hasBookingOrRequestWithAnotherPractitioner ? (
        <BookingAlertContainer>
          <Alert
            variant="info"
            orientation="vertical"
            size="medium"
            title={t('practitioner_profile.booking_disabled_alert.title')}
            body={t('practitioner_profile.booking_disabled_alert.body')}
          />
        </BookingAlertContainer>
      ) : (
        <Formik
          initialValues={initialFormValues}
          onSubmit={onSubmit}
          validationSchema={formValidationSchema}
          isInitialValid={!isDirectBookingEnabled}
        >
          {({
            values: formValues,
            setValues,
            setFieldValue,
            isSubmitting,
            isValid: isFormValid,
            status,
          }) => (
            <BookingFlowContainer>
              <Header>
                <WrappingButton
                  aria-label={t('booking_flow.close_button_aria')}
                  onClick={handleCloseDrawer}
                  type="button"
                >
                  <StyledIcon Icon={Close} />
                </WrappingButton>
                <PractitionerTitleText ref={titleRef}>
                  {titleText}
                </PractitionerTitleText>
              </Header>
              <ContentContainer>
                {formValues.bookingStage === BookingStage.DateSelect && (
                  <BookingStageDateSelect
                    formValues={formValues}
                    setValues={setValues}
                    setFieldValue={setFieldValue}
                    availableTimes={practitioner.availability}
                  />
                )}
                {formValues.bookingStage === BookingStage.Confirm && (
                  <BookingStageConfirm
                    isSubmitting={isSubmitting}
                    isDirectBookingEnabled={isDirectBookingEnabled}
                    formValues={formValues}
                    setFieldValue={setFieldValue}
                    practitionerName={practitionerName}
                    onBackSelected={() =>
                      setFieldValue(FieldValues.Stage, BookingStage.DateSelect)
                    }
                    availableTimes={practitioner.availability}
                  />
                )}
              </ContentContainer>
              <ConfirmFooter
                formValues={formValues}
                setFieldValue={setFieldValue}
                isSubmitting={isSubmitting}
                isValid={isFormValid}
                status={status}
              />
            </BookingFlowContainer>
          )}
        </Formik>
      )}
    </Drawer>
  );
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { userQuery } from 'Services/User/__generated__/userQuery';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import Route404 from 'App/Router/Route404';
import RoutePath from 'App/RoutePath';
import { useTranslation } from 'react-i18next';
import { ChatbotInput } from 'Assistant/Chatbot';
import { UserMessage } from 'Assistant/Chatbot/types';
import { tracking } from 'App/Tracking';
import { useSubmitInitialMessage } from 'Assistant/Chatbot/hooks';
import { isMSTeams } from 'utils/MSTeams';
import { USER_QUERY } from '../../graphql';
import { SuggestionsList } from '../../components/SuggestionsList';
import { TipsModal } from '../../components/TipsModal';
import {
  PageContainer,
  MainContainer,
  ChatBotInputWrapper,
  SuggestionsListWrapper,
  Title,
  Body,
} from './styles';

const Heading = () => {
  const { data } = useQuery<userQuery>(USER_QUERY);
  const { t: translate } = useTranslation('chatbot');

  return (
    <>
      <Title>
        {data?.user?.firstName
          ? translate('coach_landing_page.title.title_personal', {
              name: data.user.firstName,
            })
          : translate('coach_landing_page.title.title_generic')}
      </Title>
      <Body>{translate('coach_landing_page.subtitle')}</Body>
    </>
  );
};

export const ChatbotLandingPage = () => {
  const isChatBotEnabled = useFeatureFlag(FEATURE_FLAGS.ENABLE_CHATBOT);
  const history = useHistory();
  const [shouldSubmitInitialMessage, setSubmitInitialMessage] =
    useSubmitInitialMessage();

  const handleSubmitRedirect = async (message: UserMessage) => {
    if (!shouldSubmitInitialMessage) {
      await setSubmitInitialMessage(true);
    }

    if (isMSTeams()) {
      history.push(
        `${RoutePath.Chatbot}?content=${message.content}&topic=${message.topic}&starterId=${message.starterId}`,
      );
    } else {
      history.push(RoutePath.Chatbot, message);
    }
  };

  if (!isChatBotEnabled) {
    return <Route404 />;
  }

  return (
    <PageContainer>
      <MainContainer>
        <Heading />
        <ChatBotInputWrapper>
          <ChatbotInput canSubmit={true} onSubmit={handleSubmitRedirect} />
        </ChatBotInputWrapper>
      </MainContainer>
      <SuggestionsListWrapper>
        <SuggestionsList
          handleSuggestionClick={async message => {
            tracking.track('ai-coach-chatbot-starter-suggestion-clicked', {
              source: 'ai-coach-chatbot-landing-page',
              message: message.content,
            });
            await handleSubmitRedirect(message);
          }}
        />
      </SuggestionsListWrapper>
      <TipsModal />
    </PageContainer>
  );
};

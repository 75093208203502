import { Formik } from 'formik';
import { rem } from 'polished';
import queryString from 'query-string';
import * as moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import type { ApolloClient } from '@apollo/client';
import { Link, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { styled } from 'styles';
import { Chevron, MagicBubble } from 'icons';
import { isNil } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { Helmet } from 'react-helmet-async';
import { isMSTeams } from 'utils/MSTeams';
import RoutePath from '../../App/RoutePath';
import { tracking } from '../../App/Tracking';
import {
  FormikInput,
  FormikValidatedPasswordInput,
  validateEmail,
  validateFieldNotEmpty,
} from '../../Shared/Form/Formik';

import {
  AuthWrapperBlock,
  AuthWrapperContent,
  AuthWrapper,
  AuthWrapperSubtitle,
  AuthWrapperTitle,
} from '../AuthWrapper';
import {
  StyledForm,
  StyledFormFieldLabel,
  StyledSubmitButton,
} from '../SignUp/Forms/CommonFormStyledComponents';
import { useSubdomainInfo } from '../SignUp/useSubdomainInfo';
import { configureSentryUserScope } from '../../App/logging';
import withFeatureFlagUserContext from '../../flags/withFeatureFlagUserContext';
import { WithFeatureFlagUserContextProps } from '../../flags/types';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import { useGroupId } from '../useGroupId';
import { AlertBox } from '../../Shared/Form';
import withUpdateUser, {
  WithUpdateUserProps,
} from '../../Account/withUpdateUser';
import { AuthPayload, AuthStatus, LoginAction } from '../../App/Auth';
import { Errors } from '../../Shared/Errors';
import {
  withConfirmAndAuthUser,
  WithConfirmAndAuthUserProps,
} from '../withConfirmAndAuthUser';
import { VIRGIN_PULSE_PARTNER_NAME } from '../VirginPulse/consts';
import { identifyBrazeUserAndMergeProfiles } from '../../App/braze/identifyBrazeUser';
import getSubdomainFromUrl from '../../utils/getSubdomainFromUrl';
import useIsUsingSSO from '../UniversalLogin/useIsUsingSSO';
import { BodyText } from '../../Shared/Typography';
import {
  withLoginWithToken,
  WithLoginWithTokenProps,
} from './withLoginWithToken';
import withLogin, { LoginVariables, WithLoginChildProps } from './withLogin';

const MagicLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const MagicLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${rem(7)};
  border: ${({ theme }) => `1px solid ${theme.colors.border.secondary}`};
  border-radius: 10px;
  margin: ${rem(26)} 0;
`;

const MagicLinkIcon = styled(MagicBubble).attrs(({ theme }) => ({
  primaryColor: theme.colors.login.panel,
  height: rem(39.36),
  width: rem(39.36),
  viewBox: '0 0 39.36 39.36',
}))`
  overflow: visible;
`;

const MagicLinkArrow = styled<any>(Chevron).attrs(({ theme }) => ({
  primaryColor: theme.colors.login.border,
  height: rem(16),
  width: rem(16),
}))`
  min-width: ${rem(16)};
  min-height: ${rem(16)};
`;

const MagicLinkText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize18,
  ],
}))`
  padding: 0 ${rem(19)} 0 ${rem(16)};
  margin-right: auto;
`;

const ForgotPasswordLink = styled.div`
  margin-top: ${rem(17)};
`;

const StyledAlertBox = styled(AlertBox)`
  margin-bottom: ${rem(16)};
`;

interface HandleAuthResponseProps {
  response: AuthPayload;
  isFirstLogin: boolean;
  loginAction: LoginAction;
}

interface FormValues {
  emailAddress: string;
  password: string;
}

export interface LoginViaUsernamePasswordProps
  extends RouteComponentProps,
    WithFeatureFlagUserContextProps,
    WithLoginChildProps,
    WithLoginWithTokenProps,
    WithConfirmAndAuthUserProps,
    WithUpdateUserProps {
  client: ApolloClient<any>;
}

export function LoginViaUsernamePassword(props: LoginViaUsernamePasswordProps) {
  const subdomain = getSubdomainFromUrl();

  const { t: translate } =
    useTranslation<Namespace<'logged_out'>>('logged_out');
  const {
    groupName,
    group,
    loading: subdomainInfoLoading,
  } = useSubdomainInfo({
    subdomain,
  });
  const { groupId } = useGroupId({ subdomain });

  const { isUsingSSO, loadingIsUsingSSO } = useIsUsingSSO();
  const [showPassword, setShowPassword] = useState(false);
  const [loginRedirect, setLoginRedirect] = useState<string | null>(null);
  const [loginError, setLoginError] = useState('');
  const [magicLoginError, setMagicLoginError] = useState<Errors | undefined>(
    undefined,
  );
  const [tokenOrRidLoginLoading, setTokenOrRidLoginLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const userTimezone = moment.tz.guess();

  const showCreateAccountLink = !isUsingSSO;
  const partner = group?.isVirginPulseGroup ? VIRGIN_PULSE_PARTNER_NAME : null;

  const handleMagicLoginError = (error: Errors) => {
    switch (error) {
      case Errors.InvalidCredentialsError:
      case Errors.TokenExpiredError:
        setMagicLoginError(error);
        break;
      case Errors.NotFoundError:
        setLoginError(translate('login.errors.user_not_found'));
        setMagicLoginError(undefined);
        break;
      default:
        setLoginError(translate('login.errors.default_login_error'));
        setMagicLoginError(undefined);
    }
  };

  const identifyLoggedInUser = (
    response: AuthPayload,
    callback: () => void,
  ) => {
    if (response.status === AuthStatus.ERROR) {
      return;
    }

    const { userType, permissions, isLineManager } = response;
    const traits = {
      activationDate: response.activationDate,
      createdAt: response.createdAt,
      uuid: response.id,
      groupId: response.groupId,
      client: response.groupName,
      clientTier: response.clientTier,
      subdomain: response.subdomain,
      praiseDirectoryEnabled: Boolean(Number(response.praiseDirectory.value)),
      firstName: response.firstName,
      lastName: response.lastName,
      email: response.email,
      department: response.departmentName,
      departmentId: response.departmentId,
      location: response.locationName,
      locationId: response.locationId,
      userType: userType && userType.name ? userType.name : undefined,
      accessType:
        permissions && permissions.value
          ? permissions.value.toLowerCase()
          : undefined,
      timezone: userTimezone,
      partner,
      identifiedAsLineManager: isLineManager,
    };

    configureSentryUserScope({
      id: response.id,
      subdomain: response.subdomain,
    });

    const userIdForTracking = response.id;

    tracking.identifyUser({
      userId: userIdForTracking,
      traits,
      callback,
    });
  };

  const onSuccessfulLogin = async (
    userId: string,
    userTraits: [string],
    isFirstLogin?: boolean,
  ) => {
    void props.identifyFeatureFlagUser?.({
      userId,
      subdomain,
      userTraits,
    });

    await identifyBrazeUserAndMergeProfiles(props.client, userId);

    await props.updateUser({
      timezone: userTimezone,
    });

    if (!isNil(loginRedirect)) {
      props.history.push(loginRedirect);
    } else {
      props.history.push(RoutePath.Home, { isFirstLogin });
    }
  };

  const handleLoginError = (error: Errors) => {
    switch (error) {
      case Errors.InvalidCredentialsError:
        setLoginError(translate('login.errors.invalid_credentials'));
        break;

      case Errors.AccountLockedError:
        setLoginError(translate('login.errors.account_locked'));
        break;

      default:
        setLoginError(translate('login.errors.default_login_error'));
    }
  };

  const handleAuthResponse = async ({
    response,
    isFirstLogin,
    loginAction,
  }: HandleAuthResponseProps) => {
    setLoading(false);
    switch (response.status) {
      case AuthStatus.DEGRADED: {
        const { token: accessToken, id, userTraits } = response;
        localStorage.setItem('token', accessToken);
        identifyLoggedInUser(response, () => {
          tracking.track('login-successful', {
            subdomain,
            isFirstLogin,
            loginAction,
            type: 'degraded',
          });
        });

        await onSuccessfulLogin(id, userTraits, isFirstLogin);

        break;
      }
      case AuthStatus.SUCCESS: {
        const {
          iris_token: irisToken,
          token: accessToken,
          id,
          userTraits,
        } = response;

        localStorage.setItem('token', accessToken);
        localStorage.setItem('irisToken', irisToken);

        identifyLoggedInUser(response, () => {
          tracking.track('login-successful', {
            subdomain,
            isFirstLogin,
            loginAction,
            type: 'success',
          });
        });
        await onSuccessfulLogin(id, userTraits, isFirstLogin);
        break;
      }
      case AuthStatus.ERROR: {
        handleLoginError(response.error);
        tracking.track('login-failed', { subdomain });
        break;
      }
      default: {
        handleLoginError(Errors.ServerError);
        tracking.track('login-failed', { subdomain });
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      const { location, loginWithToken, confirmAndAuthUser } = props;
      const { search } = location;
      const query = queryString.parse(search);
      const { token, redirect, rid } = query;

      if (!(isNil(redirect) || Array.isArray(redirect))) {
        setLoginRedirect(redirect);
      }

      if (Array.isArray(token) || Array.isArray(rid)) {
        return;
      }

      if (!isNil(token) || !isNil(rid)) {
        let response: AuthPayload;

        if (!isNil(token) && token.length > 0) {
          setTokenOrRidLoginLoading(true);
          response = await loginWithToken({ token });

          await handleAuthResponse({
            response,
            isFirstLogin: false,
            loginAction: LoginAction.MAGIC_LINK,
          });

          if (response.status === AuthStatus.ERROR) {
            setTokenOrRidLoginLoading(false);

            handleMagicLoginError(response.error);
          }

          return;
        }

        if (!isNil(rid) && rid.length > 0) {
          setTokenOrRidLoginLoading(true);

          response = await confirmAndAuthUser({ rid });
          await handleAuthResponse({
            response,
            isFirstLogin: true,
            loginAction: LoginAction.EMAIL_CONFIRMATION,
          });

          if (response.status === AuthStatus.ERROR) {
            setTokenOrRidLoginLoading(false);

            handleMagicLoginError(response.error);
          }

          return;
        }

        setLoginError(translate('login.errors.default_login_error'));
      }
    })();
  }, []);

  const onSubmit = async (values: FormValues) => {
    const { emailAddress, password } = values;

    if (!emailAddress || !password) {
      return;
    }

    setLoading(true);
    setLoginError('');
    setMagicLoginError(undefined);

    const { login } = props;
    const loginVariables: LoginVariables = {
      emailAddress,
      groupId,
      password,
    };

    const response = await login(loginVariables);

    await handleAuthResponse({
      response,
      isFirstLogin: false,
      loginAction: LoginAction.DIRECT_LOGIN,
    });
  };

  const onPasswordDisplayToggle = (displayed: boolean) => {
    tracking.track('password-displayed-toggled', {
      subdomain,
      displayed,
      partner,
    });

    setShowPassword(!showPassword);
  };

  const onMagicLinkClicked = () => {
    tracking.track('magic-link-clicked', { subdomain });
  };

  const magicLinkText = Trans({
    t: translate,
    i18nKey: 'login.magic_link',
    defaults:
      'Long password? We’ll email you a <strong>magic link</strong> for a password-free sign-in',
    components: {
      2: <strong />,
    },
  });

  if (tokenOrRidLoginLoading || subdomainInfoLoading || loadingIsUsingSSO) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Helmet
        meta={[
          {
            name: `robots`,
            content: 'noindex',
          },
        ]}
      />
      <AuthWrapper subdomain={groupName}>
        <AuthWrapperBlock>
          <AuthWrapperTitle>{translate('login.title')}</AuthWrapperTitle>
          {showCreateAccountLink ? (
            <AuthWrapperSubtitle>
              {translate('login.not_member')}{' '}
              <Link
                to={RoutePath.SignUp}
                onClick={() => {
                  tracking.track('create-account-clicked', {
                    subdomain,
                    origin: 'login',
                    partner,
                  });
                }}
                data-testid="create-account-link"
              >
                {translate('login.sign_up_link')}
              </Link>
            </AuthWrapperSubtitle>
          ) : null}

          <AuthWrapperContent>
            <div>
              <Formik
                initialValues={{ emailAddress: '', password: '' }}
                onSubmit={onSubmit}
              >
                {({ isValid }) => (
                  <StyledForm>
                    <StyledFormFieldLabel htmlFor="emailAddress">
                      {translate('login.email_field.label')}
                    </StyledFormFieldLabel>
                    <FormikInput
                      name="emailAddress"
                      placeholder={translate(
                        'login.email_field.placeholder_text',
                      )}
                      aria-label={translate(
                        'login.email_field.input_field_label',
                      )}
                      type="email"
                      validate={validateEmail}
                      expandHeight={false}
                      data-testid="email-input"
                    />
                    <StyledFormFieldLabel htmlFor="password">
                      {translate('login.password_field.label')}
                    </StyledFormFieldLabel>
                    <FormikValidatedPasswordInput
                      showValidator={false}
                      data-testid="password-input"
                      name="password"
                      placeholder={translate(
                        'login.password_field.placeholder_text',
                      )}
                      aria-label={translate(
                        'login.password_field.input_field_label',
                      )}
                      type={showPassword ? 'text' : 'password'}
                      onToggleSecureText={onPasswordDisplayToggle}
                      validate={e =>
                        validateFieldNotEmpty(
                          e,
                          translate('login.errors.password_required'),
                        )
                      }
                      expandHeight={false}
                    />
                    <ForgotPasswordLink>
                      <Link
                        to={RoutePath.ForgotPassword}
                        onClick={() => {
                          tracking.track('forgot-password-clicked', {
                            subdomain,
                          });
                        }}
                        data-testid="forgotten-password-link"
                      >
                        {translate('login.password_field.forgot_password')}
                      </Link>
                    </ForgotPasswordLink>
                    {!isMSTeams() ? (
                      <MagicLink
                        to={RoutePath.MagicLogin}
                        onClick={onMagicLinkClicked}
                        data-testid="magic-link-button"
                      >
                        <MagicLinkWrapper>
                          <MagicLinkIcon />
                          <MagicLinkText>{magicLinkText}</MagicLinkText>
                          <MagicLinkArrow />
                        </MagicLinkWrapper>
                      </MagicLink>
                    ) : null}
                    <StyledSubmitButton
                      data-testid="sign-in-button"
                      type="submit"
                      label={translate('login.submit_button.label')}
                      disabled={!isValid}
                      loading={loading}
                      onClick={() => {
                        tracking.track('login-button-clicked', {
                          subdomain,
                        });
                      }}
                    />
                    {Boolean(loginError) && !magicLoginError ? (
                      <StyledAlertBox alertType="failed" message={loginError} />
                    ) : null}
                    {magicLoginError !== undefined ? (
                      <StyledAlertBox
                        alertType="failed"
                        data-testid="form-error"
                      >
                        {magicLoginError === Errors.InvalidCredentialsError ? (
                          <span>
                            {translate('login.errors.magic_link_invalid')}{' '}
                            <Link to={RoutePath.MagicLogin}>
                              {translate('login.here')}
                            </Link>
                            .
                          </span>
                        ) : (
                          <span>
                            {translate('login.errors.magic_link_expired')}{' '}
                            <Link to={RoutePath.MagicLogin}>
                              {translate('login.here')}
                            </Link>
                            .
                          </span>
                        )}
                      </StyledAlertBox>
                    ) : null}
                  </StyledForm>
                )}
              </Formik>
            </div>
          </AuthWrapperContent>
        </AuthWrapperBlock>
      </AuthWrapper>
    </>
  );
}

export default compose<LoginViaUsernamePasswordProps, RouteComponentProps>(
  withApollo,
  withFeatureFlagUserContext,
  withLogin,
  withLoginWithToken,
  withConfirmAndAuthUser,
  withUpdateUser,
)(LoginViaUsernamePassword);

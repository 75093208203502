import React from 'react';
import { compose } from 'recompose';
import { RouteComponentProps } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { Link } from 'react-router-dom';
import { EmailAddress } from '../EmailConfirmation/EmailConfirmation.styled';
import {
  withSubdomainFromUrl,
  ChildProps as SubdomainFromUrlProps,
} from '../withSubdomainFromUrl';
import {
  AuthWrapper,
  AuthWrapperBlock,
  AuthWrapperBlockContainer,
  AuthWrapperTitle,
  AuthWrapperContent,
  AuthWrapperSubtitle,
} from '../AuthWrapper';
import { useSubdomainInfo } from '../SignUp/useSubdomainInfo';
import RoutePath from '../../App/RoutePath';
import {
  EmailConfirmationFooter,
  EmailConfirmationHelperText,
} from '../EmailConfirmation/EmailConfirmationShared';

export interface MagicLinkConfirmationProps
  extends SubdomainFromUrlProps,
    RouteComponentProps {}

export function MagicLoginConfirmation(props: MagicLinkConfirmationProps) {
  const { subdomain } = props;
  const { groupName } = useSubdomainInfo({
    subdomain,
  });
  const { t: translate } =
    useTranslation<Namespace<'logged_out'>>('logged_out');

  const email =
    props.location.state && props.location.state.email
      ? props.location.state.email
      : translate('login.magic_login_confirmation.email_placeholder_text');

  return (
    <AuthWrapper subdomain={groupName}>
      <AuthWrapperBlock>
        <AuthWrapperBlockContainer>
          <AuthWrapperTitle>
            {translate('login.magic_login_confirmation.heading')}
          </AuthWrapperTitle>
          <AuthWrapperSubtitle>
            {Trans({
              t: translate,
              i18nKey: 'login.magic_login_confirmation.subtitle',
              values: { email },
              components: { email_address: <EmailAddress /> },
            })}
          </AuthWrapperSubtitle>
          <AuthWrapperContent>
            <EmailConfirmationHelperText>
              {Trans({
                t: translate,
                i18nKey: 'login.magic_login_confirmation.helper_text',
                components: {
                  confirmation_link: (
                    <Link
                      to={RoutePath.MagicLogin}
                      data-testid="try-again-link"
                    />
                  ),
                },
              })}
            </EmailConfirmationHelperText>
            <EmailConfirmationFooter userEmailAddress={email} />
          </AuthWrapperContent>
        </AuthWrapperBlockContainer>
      </AuthWrapperBlock>
    </AuthWrapper>
  );
}

export default compose<MagicLinkConfirmationProps, RouteComponentProps>(
  withSubdomainFromUrl,
)(MagicLoginConfirmation);

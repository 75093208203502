import useFeatureFlag from 'flags/useFeatureFlag';
import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

declare const VERSION: string;

const datadogEnabled = process.env.REACT_APP_DATADOG_ENABLED === 'true';
const datadogEnvironment = process.env.REACT_APP_DATADOG_ENVIRONMENT || 'dev';
const datadogApplicationId = process.env.REACT_APP_DATADOG_APPLICATION_ID || '';
const datadogClientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '';

type DatadogInitOptions = {
  sampleRate?: number;
  sessionReplaySampleRate?: number;
  trackFrustrations?: boolean;
  trackLongTasks?: boolean;
  trackResources?: boolean;
  trackUserInteractions?: boolean;
};

export const DatadogSetup = () => {
  const datadogInitOptions = useFeatureFlag<DatadogInitOptions>(
    'datadog-init-options',
  );

  const datadogShouldStartSessionReplayRecording = useFeatureFlag(
    'datadog-start-session-replay-recording',
  );

  useEffect(() => {
    if (datadogEnabled && datadogInitOptions) {
      /**
       * Datadog RUM can be re-initialised with different
       * configuration options, controlled via a Launch Darkly flag.
       */
      datadogRum.init({
        applicationId: datadogApplicationId,
        clientToken: datadogClientToken,
        site: process.env.REACT_APP_DATADOG_SITE || 'datadoghq.eu',
        service: 'web-frontend',
        env: datadogEnvironment,
        version: VERSION,
        defaultPrivacyLevel: 'mask-user-input',
        ...datadogInitOptions,
      });

      if (datadogShouldStartSessionReplayRecording) {
        datadogRum.startSessionReplayRecording();
      }
    }
  }, [datadogInitOptions, datadogShouldStartSessionReplayRecording]);

  return null;
};

export const setDatadogUser = (user: {
  id: string;
  subDomain: string;
  organisationId: string;
  isLineManager: boolean;
  locale: string;
  timezone: string;
  userType: string;
  role: string;
  departmentName?: string;
  locationName?: string;
}) => {
  if (datadogEnabled) {
    datadogRum.setUser(user);
  }
};

import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { css, styled } from 'styles';
import { compose } from 'recompose';
import { Field, FieldProps, Formik } from 'formik';
import { rem } from 'polished';
import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import {
  AuthWrapper,
  AuthWrapperBlock,
  AuthWrapperSubtitle,
  AuthWrapperTitle,
} from '../../../LoggedOut/AuthWrapper';
import RoutePath from '../../RoutePath';
import { AlertBox } from '../../../Shared/Form';
import LoadingIndicator from '../../../Shared/LoadingIndicator';
import { StyledForm } from '../../../LoggedOut/SignUp/Forms/CommonFormStyledComponents';
import {
  ChildProps as SubdomainFromUrlProps,
  withSubdomainFromUrl,
} from '../../../LoggedOut/withSubdomainFromUrl';
import PrimaryButton from '../../../Shared/PrimaryButton';
import { FormikSelect } from '../../../Shared/Form/Formik';
import { SelectSize } from '../../../Shared/Form/Select';
import { filterNonNull } from '../../../typescript/helpers';
import { small, medium } from '../../../utils';
import { tracking } from '../../../App/Tracking';
import { MarketingCheckbox } from '../../../LoggedOut/SignUp/Checkboxes';
import BodyText from '../../../Shared/Typography/BodyText';
import { AdditionalInformationNeededLabel } from './AdditionalInformationNeededLabel';
import { updateUserWorkDetails } from './__generated__/updateUserWorkDetails';
import {
  additionalInformationNeeded,
  additionalInformationNeededVariables,
} from './__generated__/additionalInformationNeeded';

export interface AdditionalInformationNeededProps
  extends RouteComponentProps,
    SubdomainFromUrlProps {}

const FieldWrapper = styled.div`
  margin-bottom: ${rem('16px')};

  ${small(css`
    margin-bottom: ${rem('32px')};
  `)}

  ${medium(css`
    margin-bottom: ${rem('24px')};
  `)}
`;

const SubmitButton = styled(PrimaryButton)`
  width: 100%;
  margin-top: ${rem('24px')};
`;

const Smallprint = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
  forwardedAs: 'p',
}))`
  margin-bottom: ${rem(20)};
`;

const AdditionalInformationForm = styled.div`
  margin-top: ${rem(20)};
`;

const UPDATE_USER_WORK_DETAILS = gql`
  mutation updateUserWorkDetails($userInput: UpdateUserInput!) {
    updateUser(userInput: $userInput)
  }
`;

export const ADDITIONAL_INFORMATION_QUERY = gql`
  query additionalInformationNeeded($subdomain: String!) {
    subDomain(subdomain: $subdomain) {
      groupName
      locations {
        id
        name
      }
      departments {
        id
        name
      }
    }
  }
`;

export const AdditionalInformationNeeded = ({
  subdomain,
}: AdditionalInformationNeededProps) => {
  const { loading: additionalInformationNeededLoading, data } = useQuery<
    additionalInformationNeeded,
    additionalInformationNeededVariables
  >(ADDITIONAL_INFORMATION_QUERY, {
    variables: {
      subdomain,
    },
  });

  const [formError, setFormError] = useState(false);

  const { t: translate } = useTranslation([
    'logged_out',
    'shared',
    'password_validation',
  ]);

  const formInitialValues = {
    location: '',
    department: '',
    marketingOptIn: false,
  };

  const urlSearchParams = new URLSearchParams(location.search);
  const showPrivacyConsent =
    urlSearchParams.get('showprivacyconsent') === 'true';

  const [updateAdditionalUserInformation, { loading }] =
    useMutation<updateUserWorkDetails>(UPDATE_USER_WORK_DETAILS, {
      onCompleted: returnedData => {
        // update user returns a boolean indicating success or not
        if (!!returnedData.updateUser) {
          if (showPrivacyConsent) {
            window.location.assign(
              `${window.location.origin}${RoutePath.ExplicitPrivacyConsentNeeded}`,
            );

            return;
          }
          window.location.assign(
            `${window.location.origin}${RoutePath.SSOLoginSuccess}`,
          );
        }
      },
      onError: () => {
        setFormError(true);
      },
    });

  if (
    additionalInformationNeededLoading ||
    !data?.subDomain ||
    !data.subDomain[0]
  ) {
    return <LoadingIndicator />;
  }

  const { locations, departments, groupName } = data.subDomain[0];

  const filteredLocations = locations?.filter(filterNonNull) || [];
  const filteredDepartments = departments?.filter(filterNonNull) || [];

  const onSubmitForm = async ({
    locationId,
    departmentId,
    marketingOptIn,
  }: {
    locationId?: string;
    departmentId?: string;
    marketingOptIn?: boolean;
  }) => {
    await updateAdditionalUserInformation({
      variables: {
        userInput: {
          ...(!!locationId && { locationId }),
          ...(!!departmentId && { departmentId }),
          marketingOptIn,
          explicitHealthDataConsentGiven: true,
        },
      },
    });
  };

  return (
    <AuthWrapper subdomain={groupName}>
      <AuthWrapperBlock>
        <AuthWrapperTitle>
          {translate('logged_out:additional_information_needed.title')}
        </AuthWrapperTitle>
        <AuthWrapperSubtitle>
          {translate('logged_out:additional_information_needed.subtitle')}
        </AuthWrapperSubtitle>
        <AdditionalInformationForm>
          <Formik
            onSubmit={async values => {
              await onSubmitForm({
                locationId: !!locations ? values.location : undefined,
                departmentId: !!departments ? values.department : undefined,
                marketingOptIn: values.marketingOptIn,
              });
            }}
            initialValues={formInitialValues}
          >
            {({ isValid, setFieldValue }) => (
              <StyledForm>
                {filteredLocations.length > 0 ? (
                  <FieldWrapper>
                    <FormikSelect
                      LabelComponent={({ id, htmlFor }) => (
                        <AdditionalInformationNeededLabel
                          htmlFor={htmlFor}
                          id={id}
                          labelText={translate(
                            'logged_out:sign_up.forms.work_details.work_location_field.label',
                          )}
                          testId="location-select-label"
                        />
                      )}
                      arrowSize={{ width: 16, height: 16 }}
                      size={SelectSize.Big}
                      data-testid="location-select"
                      name="location"
                      placeholder={translate(
                        'logged_out:sign_up.forms.work_details.work_location_field.placeholder',
                      )}
                      aria-label={translate(
                        'logged_out:sign_up.forms.work_details.work_location_field.label',
                      )}
                      options={filteredLocations.map(location => ({
                        value: location.id || '',
                        label: location.name || '',
                      }))}
                    />
                  </FieldWrapper>
                ) : null}

                {filteredDepartments.length > 0 ? (
                  <FieldWrapper>
                    <FormikSelect
                      LabelComponent={({ id, htmlFor }) => (
                        <AdditionalInformationNeededLabel
                          htmlFor={htmlFor}
                          id={id}
                          labelText={translate(
                            'logged_out:sign_up.forms.work_details.department_field.label',
                          )}
                          testId="department-select-label"
                        />
                      )}
                      arrowSize={{ width: 16, height: 16 }}
                      size={SelectSize.Big}
                      data-testid="department-select"
                      name="department"
                      placeholder={translate(
                        'logged_out:sign_up.forms.work_details.department_field.placeholder',
                      )}
                      aria-label={translate(
                        'logged_out:sign_up.forms.work_details.department_field.a11y_label',
                      )}
                      options={filteredDepartments.map(department => ({
                        value: department.id || '',
                        label: department.name || '',
                      }))}
                    />
                  </FieldWrapper>
                ) : null}

                <Smallprint>
                  {translate(
                    'logged_out:additional_information_needed.smallprint',
                  )}
                </Smallprint>

                <Field name="marketingOptIn">
                  {({ field }: FieldProps) => (
                    <MarketingCheckbox
                      {...field}
                      id="marketing-consent-checkbox"
                      setFieldValue={setFieldValue}
                      checked={field.value as boolean}
                      smallTextSize
                    />
                  )}
                </Field>
                <SubmitButton
                  data-testid="sso-complete-your-account-button"
                  label={translate(
                    'logged_out:additional_information_needed.submit_button.title',
                  )}
                  disabled={!isValid}
                  ariaLabel={translate(
                    'logged_out:additional_information_needed.submit_button.a11y_label',
                  )}
                  loading={loading}
                  type="submit"
                  onClick={() => {
                    tracking.track('sso-complete-account-clicked', {
                      subdomain,
                    });
                  }}
                />
                {formError ? (
                  <AlertBox
                    alertType="failed"
                    // for now, just add a generic error message
                    message={translate(
                      'logged_out:login.errors.default_login_error',
                    )}
                  />
                ) : null}
              </StyledForm>
            )}
          </Formik>
        </AdditionalInformationForm>
      </AuthWrapperBlock>
    </AuthWrapper>
  );
};

export default compose<AdditionalInformationNeededProps, RouteComponentProps>(
  withSubdomainFromUrl,
)(AdditionalInformationNeeded);

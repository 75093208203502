import React from 'react';
import { Loader } from '@unmind/design-system-components-web';
import { useTheme } from 'styles';
import { ChatbotMessageRole } from '__generated__/globalTypes';
import { useTranslation } from 'react-i18next';
import { AssistantMessage, Message } from 'Assistant/Chatbot/types';
import Markdown from 'react-markdown';
import { KnownReferrer } from 'hooks/useKnownReferrer';
import { tracking } from 'App/Tracking';
import { ChatbotFeedback } from '../ChatbotFeedback';
import {
  MessageIconWrapper,
  AssistantIcon,
  UserInitialText,
  ProfileIcon,
} from '../styles';
import {
  MessageCard,
  MessageText,
  MessageTextWrapper,
  OptionsWrapper,
  LoadingWrapper,
} from './styles';

type ChatbotMessageProps = {
  message: Message;
  userFirstName?: string;
  conversationId?: string;
};

type ChatbotOptionMessageProps = {
  message: AssistantMessage;
  options: React.ReactNode;
};

const AssistantMessageIcon = () => (
  <MessageIconWrapper sender={ChatbotMessageRole.assistant}>
    <AssistantIcon />
  </MessageIconWrapper>
);

const UserMessageIcon = ({ userFirstName }: { userFirstName?: string }) => {
  const firstLetter = userFirstName?.charAt(0).toUpperCase();

  return firstLetter ? (
    <MessageIconWrapper sender={ChatbotMessageRole.user}>
      <UserInitialText>{firstLetter}</UserInitialText>
    </MessageIconWrapper>
  ) : (
    <ProfileIcon />
  );
};

export const ChatbotMessage = ({
  message,
  userFirstName,
  conversationId,
}: ChatbotMessageProps) => (
  <MessageCard sender={message.role} data-message-id={message.id}>
    {message.role === ChatbotMessageRole.user ? (
      <>
        <UserMessageIcon userFirstName={userFirstName} />
        <MessageTextWrapper style={{ whiteSpace: 'pre-wrap' }}>
          <MessageText sender={message.role}>
            <Markdown>{message.content}</Markdown>
          </MessageText>
        </MessageTextWrapper>
      </>
    ) : (
      <>
        <AssistantMessageIcon />
        <MessageTextWrapper>
          <MessageText sender={message.role}>
            <Markdown
              components={{
                a: ({ node, ...props }) => (
                  <a
                    {...props}
                    href={`${props.href}?referrer=${KnownReferrer.Nova}`}
                    onClick={() => {
                      tracking.track('nova-url-clicked', {
                        url: props.href,
                        source: 'ai-coach-chatbot-ui',
                      });
                    }}
                  >
                    {props.children}
                  </a>
                ),
              }}
            >
              {message.source}
            </Markdown>
          </MessageText>
          <ChatbotFeedback
            messageId={message.id}
            conversationId={conversationId}
          />
        </MessageTextWrapper>
      </>
    )}
  </MessageCard>
);

export const ChatbotOptionsMessage = ({
  message,
  options,
}: ChatbotOptionMessageProps) => (
  <MessageCard sender={ChatbotMessageRole.assistant} hasOptions={true}>
    <MessageTextWrapper hasOptions={true}>
      <MessageIconWrapper sender={ChatbotMessageRole.assistant}>
        <AssistantMessageIcon />
      </MessageIconWrapper>

      <MessageText
        sender={message.role}
        dangerouslySetInnerHTML={{ __html: message.source }}
      />
    </MessageTextWrapper>
    <OptionsWrapper>{options}</OptionsWrapper>
  </MessageCard>
);

export const ChatbotLoadingMessage = () => {
  const theme = useTheme();
  const { t: translate } = useTranslation('chatbot');

  return (
    <MessageCard sender={ChatbotMessageRole.assistant}>
      <MessageTextWrapper isLoading={true}>
        <MessageIconWrapper sender={ChatbotMessageRole.assistant}>
          <AssistantMessageIcon />
        </MessageIconWrapper>
        <LoadingWrapper
          aria-label={translate('conversation.loading.a11y_label')}
        >
          <Loader
            color={theme.colors.chatbot.messages.loading.bubbles}
            size={4}
          />
        </LoadingWrapper>
      </MessageTextWrapper>
    </MessageCard>
  );
};

import { BodyText, extraSmall } from '@unmind/design-system-components-web';
import { rem } from 'polished';
import { css, styled } from 'styles';

export const ChatbotSessionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
`;

export const ChatbotSessionMessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  gap: ${rem(16)};
  padding: ${rem(16)};
  border-radius: ${rem(20)};
  background-color: ${({ theme }) =>
    theme.colors.chatbot.messages.user.background};

  ${extraSmall(css`
    gap: ${rem(24)};
    padding: ${rem(24)};
  `)}
`;

export const ChatbotSessionDate = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
  weight: theme.typography.fontWeights.regular,
  color: theme.colors.text.secondary,
}))`
  text-align: center;
`;

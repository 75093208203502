const formatLocale = (locale: string) => {
  if (!locale.includes('-')) {
    return locale;
  }

  const [language, countryCode] = locale.split('-');

  return `${language}-${countryCode.toUpperCase()}`;
};

export default formatLocale;

import { rem } from 'polished';
import {
  BodyText,
  Container,
  HeadingText,
  small,
} from '@unmind/design-system-components-web';
import { css, styled } from 'styles';

export const PageContainer = styled(Container)`
  height: 100%;
  padding-bottom: ${rem(24)};
  padding-top: ${rem(42)};

  display: flex;
  height: 100%;
  gap: ${rem(36)};
  flex-direction: column;
  align-items: center;

  ${small(css`
    flex-direction: row;
    gap: ${rem(86)};
    margin-bottom: ${rem(36)};
  `)}

  overflow: auto;
`;

export const MainContainer = styled.div`
  max-width: ${rem(500)};
  ${small(css`
    flex: 1;
  `)}
`;

export const SuggestionsListWrapper = styled.div`
  max-width: ${rem(500)};
  width: 100%;
  margin-bottom: ${rem(42)};
  ${small(css`
    flex: 1;
  `)};
`;

export const ChatBotInputWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: ${rem(500)};

  ${small(css`
    margin: revert;
  `)}
`;

export const Title = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  sizes: [
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize32,
  ],
  weight: theme.typography.fontWeights.semiBold,
}))`
  margin-bottom: ${rem(8)};
`;

export const Body = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
  ],
}))`
  margin-bottom: ${rem(24)};
`;

import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import {
  groupIdResponse as GroupIdResponse,
  groupIdResponseVariables as InputProps,
} from './__generated__/groupIdResponse';

interface UseGroupIdResponse {
  groupId?: string | null;
  groupIdLoading: boolean;
}

export const GROUPID_QUERY = gql`
  query groupIdResponse($subdomain: String!) {
    subDomain(subdomain: $subdomain) {
      groupId
    }
  }
`;

export function useGroupId(props: InputProps): UseGroupIdResponse {
  const { data, loading } = useQuery<GroupIdResponse>(GROUPID_QUERY, {
    variables: {
      subdomain: props.subdomain,
    },
  });

  return {
    groupId:
      data?.subDomain && data?.subDomain?.length > 0
        ? data.subDomain[0]?.groupId || undefined
        : undefined,
    groupIdLoading: Boolean(loading),
  };
}

import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { css, styled } from 'styles';
import { LockRounded } from 'icons';
import { rem } from 'polished';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { getMSTeamsUserContext, isMSTeams } from 'utils/MSTeams';
import { authentication, HostClientType } from '@microsoft/teams-js';
import { small, getPrivacyPolicyLink } from '../../utils';
import {
  AuthWrapperBlock,
  AuthWrapperTitle,
  AuthWrapperSubtitle,
  createTextContentWidth,
  AuthWrapper,
} from '../AuthWrapper';
import { useSubdomainInfo } from '../SignUp/useSubdomainInfo';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import useUniversalLogin from '../UniversalLogin/useUniversalLogin';
import PrimaryButton from '../../Shared/PrimaryButton';
import { getTermsOfUseLink } from '../../utils/getTermsOfUseLink';
import getSubdomainFromUrl from '../../utils/getSubdomainFromUrl';
import RoutePath from '../../App/RoutePath';
import { tracking } from '../../App/Tracking';
import { ExternalLinkIcon } from '../ExternalLinkIcon';
import {
  authenticationErrorStorageCode,
  AuthErrorCode,
  MSTeamsAuthPopupErrorMessage,
} from './AuthError';

export type LoginViaSSOProps = RouteComponentProps;

const responsiveButtonStyle = () => css`
  ${small(css`
    padding-left: 22px;
  `)}
`;

const TermsContainer = styled.div`
  margin-bottom: ${rem('16px')};
  margin-top: ${rem('24px')};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const ContinueButton = styled(PrimaryButton)`
  margin-top: ${rem('24px')};
`;

const BoldLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text.link};
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  text-decoration: none;
`;

export const SSOLoginButton = styled(PrimaryButton)`
  background-color: ${({ theme }) =>
    theme.button.primary.default.backgroundColor};
  border-color: ${({ theme }) => theme.button.primary.default.borderColor};
  border-width: 1px;
  padding-left: 36px;

  ${responsiveButtonStyle()};
`;

const ButtonTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LockIcon = styled(LockRounded).attrs(({ theme }) => ({
  primaryColor: theme.button.primary.default.textColor,
  width: 20,
  height: 20,
}))`
  margin-right: 16px;
`;

const PlusOneTextWrapper = styled.div`
  margin-top: ${rem(24)};
  color: ${({ theme }) => theme.colors.text.secondary};
  ${createTextContentWidth()};
  font-weight: 500;
`;

function LoginViaSSO({}: LoginViaSSOProps) {
  const subdomain = getSubdomainFromUrl();

  const {
    groupName,
    authProviderOrganisationId,
    loading: subdomainInfoLoading,
  } = useSubdomainInfo({
    subdomain,
  });

  const { t: translate } =
    useTranslation<Namespace<'logged_out'>>('logged_out');

  const urlSearchParams = new URLSearchParams(location.search);
  const returnTo = urlSearchParams.get('redirect') || undefined;

  const { loginWithUniversalLogin, loginWithPopupWindow } = useUniversalLogin();

  if (subdomainInfoLoading) {
    return <LoadingIndicator />;
  }

  const handleUserAuthenticationInMSTeams = async (): Promise<void> => {
    try {
      const authenticationResult = await authentication.authenticate({
        isExternal: false,
        url: window.location.origin + RoutePath.MSTeamsSSO,
      });

      if (authenticationResult === 'success') {
        window.location.assign(RoutePath.SSOLoginHome);
      }
    } catch (e) {
      const authenticationError = localStorage.getItem(
        authenticationErrorStorageCode,
      );

      localStorage.removeItem(authenticationErrorStorageCode);

      if (authenticationError) {
        const { errorDescription, authErrorParam } =
          JSON.parse(authenticationError);

        if (errorDescription === 'EMAIL_VERIFICATION_REQUIRED') {
          window.location.assign(
            RoutePath.VerifyAccountOwnershipEmailConfirmation,
          );
        } else {
          window.location.assign(`${RoutePath.LoginWithSSO}${authErrorParam}`);
        }

        return;
      }

      if ((e as Error).message === MSTeamsAuthPopupErrorMessage) {
        window.location.assign(
          `${RoutePath.LoginWithSSO}?error_code=${AuthErrorCode.AUTH_CANCELLED_BY_USER}`,
        );

        return;
      }
    }
  };

  const context = getMSTeamsUserContext();
  let isWeb = false;
  if (context) {
    isWeb = context.app.host.clientType === HostClientType.web;
  }

  return (
    <>
      <AuthWrapper subdomain={groupName}>
        <AuthWrapperBlock>
          <AuthWrapperTitle>
            {translate('sso.access_unmind_header')}
          </AuthWrapperTitle>
          <AuthWrapperSubtitle>
            {translate('sso.access_unmind_subtitle')}
          </AuthWrapperSubtitle>
          <ContinueButton
            data-testid="sso-sign-in-button"
            label={
              <ButtonTextWrapper>
                <LockIcon /> {translate('sso.continue_button.label')}
              </ButtonTextWrapper>
            }
            aria-label={translate('sso.continue_button.aria_label')}
            onClick={async () => {
              tracking.track('sso-continue-sso-clicked', { subdomain });

              if (isMSTeams()) {
                // Due to a bug on web we've had to reintroduce the auth0 popup flow for SSO
                // details here: https://unmind.atlassian.net/browse/ENG-12806
                if (isWeb) {
                  await loginWithPopupWindow({ authProviderOrganisationId });
                } else {
                  await handleUserAuthenticationInMSTeams();
                }
              } else {
                await loginWithUniversalLogin({
                  authProviderOrganisationId: authProviderOrganisationId,
                  returnTo,
                });
              }
            }}
          />
          <TermsContainer>
            <Trans
              t={translate}
              i18nKey="sso.implicit_consent_copy"
              defaults="By continuing with Single Sign-On, I agree to Unmind’s <bold_link_terms>Terms & Conditions<external_link_icon></external_link_icon></bold_link_terms> and <bold_link_policy>Privacy Policy<external_link_icon></external_link_icon></bold_link_policy>."
              components={{
                bold_link_terms: (
                  <BoldLink
                    to={{ pathname: getTermsOfUseLink() }}
                    target="_blank"
                  />
                ),
                bold_link_policy: (
                  <BoldLink
                    to={{ pathname: getPrivacyPolicyLink() }}
                    target="_blank"
                  />
                ),
                external_link_icon: <ExternalLinkIcon />,
              }}
            />
          </TermsContainer>

          <PlusOneTextWrapper data-testid="sso-plus-one-text">
            <Trans
              t={translate}
              i18nKey="sso.plus_one_footer_help_text"
              defaults="Been given guest access to Unmind by a friend or family member? <colour>Sign in with your email address and password</colour>."
              components={{
                colour: (
                  <BoldLink
                    to={{ pathname: RoutePath.LoginWithCredentials }}
                    onClick={() => {
                      tracking.track('sso-plus-one-signin-link-clicked', {
                        subdomain,
                      });
                    }}
                  />
                ),
              }}
            />
          </PlusOneTextWrapper>
        </AuthWrapperBlock>
      </AuthWrapper>
    </>
  );
}

export default LoginViaSSO;

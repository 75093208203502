import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useState } from 'react';

export interface UseMagicLoginParams {
  email: string;
  groupId: string;
}

interface RequestMagicLoginReturnParams {
  success: boolean;
  error?: Error;
}

interface MagicLoginMutationResponse {
  magicLogin: string;
}

export const MAGIC_LOGIN_MUTATION = gql`
  mutation MagicLogin($email: String!, $groupId: String!) {
    magicLogin(email: $email, groupId: $groupId)
  }
`;

export function useMagicLogin() {
  const [magicLogin] = useMutation(MAGIC_LOGIN_MUTATION);
  const [loading, setLoading] = useState(false);

  async function requestMagicLogin(
    params: UseMagicLoginParams,
  ): Promise<RequestMagicLoginReturnParams> {
    try {
      setLoading(true);
      const { data }: { data?: MagicLoginMutationResponse } = await magicLogin({
        variables: params,
      });
      setLoading(false);

      return {
        success: Boolean(data && data.magicLogin),
      };
    } catch (e) {
      setLoading(false);
      if (e instanceof Error) {
        return {
          success: false,
          error: e,
        };
      }

      return {
        success: false,
      };
    }
  }

  return {
    requestMagicLogin,
    loading,
  };
}

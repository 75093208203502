import { useQuery } from '@apollo/client';
import React, { useMemo, useRef } from 'react';
import { css, styled } from 'styles';
import FeaturedCheatSheets from 'Home/Managers/CheatSheets/FeaturedCheatSheets';
import { getUserLocale } from 'i18n/getUserLocale';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import { TalkOnboardingModal } from 'Talk/components/TalkOnboardingModal';
import { useLocation } from 'react-router';
import useIsManagerExperienceActive from 'Home/Managers/useIsManagerExperienceActive';
import {
  LINE_MANAGERS_CATEGORY,
  SUPPORTED_LOCALES,
} from 'Home/Managers/constants';
import { useSetBrazeLanguage } from '../../App/braze/setBrazeLanguage';
import { tracking } from '../../App/Tracking';
import { HiddenOnMobile } from '../../Navigation/AuthenticatedPage';
import Container from '../../Shared/Container';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import { medium, small } from '../../utils';
import SectionError from '../../Shared/SectionError';
import Managers from '../Managers/Managers';
import ManagersToday from '../Managers/ManagersToday';
import Actions from './Actions/Actions';
import Header from './Header/Header';
import HeroBanner from './HeroBanner';
import { PERSONALISED_TODAY_CONTENT_QUERY } from './personalisedTodayQueries';
import WellbeingBoosts from './WellbeingBoosts/WellbeingBoosts';
import {
  PersonalisedTodayContent,
  PersonalisedTodayContentVariables,
} from './__generated__/PersonalisedTodayContent';

const TodayWrapper = styled(Container)`
  padding-top: 26px;
  padding-bottom: 26px;

  ${small(css`
    padding-top: 48px;
    padding-bottom: 48px;
  `)}

  ${medium(css`
    padding-top: 56px;
    padding-bottom: 56px;
  `)}
`;

export const HomeScreenSection = styled.section`
  margin-bottom: 40px;
`;

const ErrorWrapper = styled.div`
  margin-top: 56px;
`;

export const ContentCardWrapper = styled.div``;

const onNavigateExploreCategory = (categorySlug: string) => {
  tracking.track('homepage-line-manager-content-view-clicked', {
    categorySlug,
    source: 'today-managers-banner',
  });
};

const PersonalisedTodayScreen = () => {
  useSetBrazeLanguage();
  const userDateTimeISOFormat = useMemo(() => new Date().toISOString(), []);
  const userLocale = getUserLocale();
  const modalCloseReturnRef = useRef<HTMLDivElement>(null);
  const shouldShowManagerCheatSheets = useFeatureFlag(
    FEATURE_FLAGS.SHOW_MANAGER_CHEAT_SHEET_WEB,
  );
  const shouldDisplayManagersToday = useIsManagerExperienceActive();

  const { data, loading, error, refetch } = useQuery<
    PersonalisedTodayContent,
    PersonalisedTodayContentVariables
  >(PERSONALISED_TODAY_CONTENT_QUERY, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    returnPartialData: false,
    notifyOnNetworkStatusChange: true,
    variables: {
      userLocalDateTime: userDateTimeISOFormat,
    },
  });

  const { state: locationState } = useLocation();
  const isFirstLogin =
    history.state?.state?.isFirstLogin || locationState?.isFirstLogin;

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return (
      <ErrorWrapper>
        <SectionError
          onRetry={async () =>
            refetch({ userLocalDateTime: userDateTimeISOFormat })
          }
        />
      </ErrorWrapper>
    );
  }

  const assetToken = data?.getAssetToken?.assetToken;
  const beforeWorkContent = data?.user?.dailySelection?.beforeWork ?? [];
  const duringWorkContent = data?.user?.dailySelection?.duringWork ?? [];
  const afterWorkContent = data?.user?.dailySelection?.afterWork ?? [];

  const shouldDisplayManagersContent =
    data?.organisationEntitlements?.lineManagerFeatures &&
    data?.user?.isLineManager;

  const shouldDisplayManagerCheatSheets =
    shouldDisplayManagersContent &&
    SUPPORTED_LOCALES.includes(userLocale) &&
    shouldShowManagerCheatSheets;

  const wellbeingBoosts = [
    ...beforeWorkContent,
    ...(shouldDisplayManagersContent ? duringWorkContent : []),
    ...afterWorkContent,
  ];

  const lineManagerCategorySlug =
    data?.homeAssembly?.featuredCategory?.slug ?? LINE_MANAGERS_CATEGORY;

  const lineManagerFeaturedContent = data?.homeAssembly.featuredContent ?? [];

  return (
    <TodayWrapper ref={modalCloseReturnRef}>
      {shouldDisplayManagersToday ? (
        <ManagersToday
          assetToken={data?.getAssetToken?.assetToken as string}
          lineManagerCategorySlug={lineManagerCategorySlug}
          featuredContent={lineManagerFeaturedContent}
        />
      ) : (
        <>
          <HiddenOnMobile>
            <Header firstName={data?.user?.firstName} />
          </HiddenOnMobile>
          {shouldDisplayManagersContent ? (
            <>
              <Managers
                exploreCategory={data?.exploreCategory}
                onNavigateItem={onNavigateExploreCategory}
                assetToken={data?.getAssetToken?.assetToken as string}
              />
            </>
          ) : duringWorkContent.length ? (
            <HeroBanner tool={duringWorkContent[0]} assetToken={assetToken} />
          ) : null}

          {shouldDisplayManagerCheatSheets ? (
            <FeaturedCheatSheets
              assetToken={data?.getAssetToken?.assetToken as string}
            />
          ) : (
            <WellbeingBoosts
              wellbeingBoosts={wellbeingBoosts}
              assetToken={assetToken}
            />
          )}

          <Actions />
        </>
      )}
      {isFirstLogin ? (
        <TalkOnboardingModal returnFocusTo={modalCloseReturnRef} />
      ) : null}
    </TodayWrapper>
  );
};

export default PersonalisedTodayScreen;

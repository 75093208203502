import { practitionerQuery_practitioner as TPractitioner } from 'Services/BeGateway/Practitioner/__generated__/practitionerQuery';
import { AvailabilityChip } from 'Talk/components/AvailabilityChip';
import { Namespace } from 'i18next';
import { Lightning } from 'icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import placeholderImg from '../../assets/browse_practitioners.png';
import { AvatarContainer, AvatarImage } from './styles';

export const PractitionerAvatar = ({
  practitioner,
}: {
  practitioner?: TPractitioner;
}) => {
  const { t } = useTranslation<Namespace<'talk'>>('talk');
  const { firstName, photoUrls, careUseAvailability, availability } =
    practitioner || {};
  const { hasAvailability } = availability || {};
  const photoUrl = photoUrls?.[0]?.url ?? placeholderImg;

  return (
    <AvatarContainer>
      {!!(careUseAvailability && hasAvailability) && (
        <AvailabilityChip
          label={t('practitioner_profile.available')}
          Icon={Lightning}
        />
      )}
      <AvatarImage
        src={photoUrl}
        alt={`${firstName}'s photo`}
        aria-label={`${firstName}'s photo`}
      />
    </AvatarContainer>
  );
};

import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { GetOrganisationEntitlements } from './__generated__/GetOrganisationEntitlements';

export type Entitlement =
  | 'workplaceIndex'
  | 'standaloneIndex'
  | 'unmindTalk'
  | 'unmindTalkPlusOne'
  | 'lineManagerFeatures';

export const ORGANISATION_ENTITLEMENTS_QUERY = gql`
  query GetOrganisationEntitlements {
    organisationEntitlements {
      workplaceIndex
      standaloneIndex
      unmindTalk
      unmindTalkPlusOne
      lineManagerFeatures
      id
    }
  }
`;

export const useOrganisationEntitlements = <
  ProvidedEntitlement extends Entitlement,
>(
  entitlements: { entitlement: ProvidedEntitlement; default?: boolean }[],
  skip = false,
) => {
  const { loading, data } = useQuery<GetOrganisationEntitlements>(
    ORGANISATION_ENTITLEMENTS_QUERY,
    {
      fetchPolicy: 'cache-first', // nb Will only reload entitlements on full page reload
      skip,
    },
  );

  return {
    loading,
    entitlements: entitlements.reduce((acc, current) => {
      const entitlementDefault = current.default ?? false;
      acc[current.entitlement] =
        data?.organisationEntitlements?.[current.entitlement] ??
        entitlementDefault;

      return acc;
    }, {} as { [K in ProvidedEntitlement]: boolean }),
  };
};

import React, { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { useAssignFocus } from '../../Shared/Accessibility';
import getSubdomainFromUrl from '../../utils/getSubdomainFromUrl';
import {
  AuthWrapper,
  AuthWrapperBlock,
  AuthWrapperSubtitle,
  AuthWrapperTitle,
} from '../AuthWrapper';

function VerifyAccountOwnershipEmailConfirmation() {
  const headerRef = useRef<HTMLHeadingElement>(null);
  const { t: translate } =
    useTranslation<Namespace<'logged_out'>>('logged_out');
  const subdomain = getSubdomainFromUrl();

  useAssignFocus(headerRef);

  return (
    <AuthWrapper subdomain={subdomain}>
      <AuthWrapperBlock data-testid="v2-email-confirmation-screen">
        <AuthWrapperTitle ref={headerRef} tabIndex={-1}>
          {translate(
            'sign_up.verify_account_ownership_email_confirmation.heading',
          )}
        </AuthWrapperTitle>
        <AuthWrapperSubtitle data-testid="email-confirmation-subtitle">
          {Trans({
            t: translate,
            i18nKey:
              'sign_up.verify_account_ownership_email_confirmation.subtitle',
            defaults: 'We’ve sent a verification email to your email',
          })}
        </AuthWrapperSubtitle>
      </AuthWrapperBlock>
    </AuthWrapper>
  );
}

export default VerifyAccountOwnershipEmailConfirmation;

import { Field, FieldProps, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styles';
import FieldError from '../../../Shared/Form/FieldError';
import { FormikInput, validateEmail } from '../../../Shared/Form/Formik';
import { medium } from '../../../utils';
import { MarketingCheckbox } from '../Checkboxes';
import {
  StyledForm,
  StyledFormFieldLabel,
  StyledSubmitButton,
} from './CommonFormStyledComponents';

const Form = styled(StyledForm)`
  // Target all browsers except ie
  @supports not (-ms-high-contrast: none) {
    ${medium(css`
      flex: 0;
    `)}
  }
`;

export interface FormFields {
  emailInput: string;
  marketingOptIn?: boolean;
}

export interface EmailInputFormProps {
  inputLabel?: string;
  placeholder?: string;
  submitLabel?: string;
  isInitialValid?: boolean;
  initialValues?: FormFields;
  setError?(error: string): void;
  error?: string;
  loading?: boolean;
  formikRef?: React.RefObject<Formik<FormFields>>;
  onSubmit(values: FormFields): void;
  showMarketingCheckbox?: boolean;
}

const EmailInputForm = ({
  inputLabel,
  placeholder,
  submitLabel,
  isInitialValid = false,
  showMarketingCheckbox = false,
  initialValues = showMarketingCheckbox
    ? { emailInput: '', marketingOptIn: false }
    : { emailInput: '' },
  ...props
}: EmailInputFormProps) => {
  const { t: translate } = useTranslation('logged_out');

  const emailInputLabel =
    inputLabel ?? translate('sign_up.forms.email_input.email_field.label');
  const emailPlaceholder =
    placeholder ?? translate('sign_up.forms.email_input.placeholder_text');
  const submitButtonLabel =
    submitLabel ?? translate('sign_up.forms.email_input.submit_button.label');

  return (
    <Formik
      isInitialValid={isInitialValid}
      initialValues={initialValues}
      onSubmit={values => props.onSubmit(values)}
      ref={props.formikRef}
    >
      {({ isValid, setFieldValue }) => (
        <Form data-testid="email-input-form">
          <StyledFormFieldLabel
            data-testid="email-input-label"
            htmlFor="emailInput"
          >
            {emailInputLabel}
          </StyledFormFieldLabel>

          <FormikInput
            data-testid="email-input"
            name="emailInput"
            placeholder={emailPlaceholder}
            aria-label={emailInputLabel}
            type="email"
            validate={validateEmail}
            onFocus={() => (props.setError ? props.setError('') : null)}
          />
          {showMarketingCheckbox ? (
            <Field name="marketingOptIn">
              {({ field }: FieldProps) => (
                <MarketingCheckbox
                  {...field}
                  id="sign-up-marketing"
                  setFieldValue={setFieldValue}
                  checked={field.value as boolean}
                />
              )}
            </Field>
          ) : null}

          {props.error ? <FieldError message={props.error} /> : null}
          <StyledSubmitButton
            data-testid="submit-button"
            type="submit"
            label={submitButtonLabel}
            disabled={!isValid}
            loading={props.loading}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EmailInputForm;

import React from 'react';
import { IconProps } from '../IconProps';

const SvgKeyBubble = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg fill="none" viewBox="0 0 52 52" width="1em" height="1em" {...props}>
    <rect width={52} height={52} rx={26} fill="#F1F0F5" />
    <rect width={52} height={52} rx={26} />
    <path
      d="M32.501 36.75h3.25m-1.625-1.625v3.25M13.814 19.688h4.875m-2.438-2.438v4.875m12.165-.205a2.665 2.665 0 1 0 5.33 0 2.665 2.665 0 0 0-5.33 0v0z"
      stroke="#1B1A29"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.76 25.1L14.468 35.392a2.221 2.221 0 1 0 3.142 3.141l1.565-1.566 1.58 1.58a1.777 1.777 0 1 0 2.513-2.514l-1.579-1.58 6.212-6.211A7.108 7.108 0 1 0 24.76 25.1z"
      stroke="#1B1A29"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgKeyBubble;

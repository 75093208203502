import React, { ComponentType } from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { Route } from 'react-router';
import { compose } from 'recompose';
import { RouteConfig } from '../routes';
import { useTrackLoggedInUser } from '../Tracking';
import usePageTitle from './usePageTitle';
import usePageTracking from './usePageTracking';
interface RouteWithSubRouteProps extends RouteConfig, React.Attributes {}

const render =
  (route: RouteWithSubRouteProps) => (props?: { [propName: string]: any }) => {
    if (!route.component) {
      return null;
    }

    const Component = route.component as ComponentType<any>;

    return <Component {...props} routes={route.routes} />;
  };

const RouteWithSubRoute = ({
  client,
  trackingConfig,
  ...props
}: WithApolloClient<RouteWithSubRouteProps>) => {
  usePageTitle(props.title);
  useTrackLoggedInUser(client);
  usePageTracking(props.title, trackingConfig);

  return (
    <Route
      path={props.path}
      render={props.render ? props.render : render(props)}
    />
  );
};

export default compose<
  WithApolloClient<RouteWithSubRouteProps>,
  RouteWithSubRouteProps
>(withApollo)(RouteWithSubRoute);

import { rem } from 'polished';
import { css, styled } from 'styles';
import { GridItem } from '../../Shared/Grid';
import { small, medium, large } from '../../utils';
import { TABLET_HEIGHT } from '../../Navigation/UnauthenticatedPage';
import { FontFamilies } from '../../styles/typography';
import { BodyText, FeatureText, MonoText } from '../../Shared/Typography';

export const createTextContentWidth = () => css`
  ${small(css`
    max-width: ${({ theme }) => (7 / theme.grid.columns.tablet) * 100}%;
  `)}

  ${medium(css`
    max-width: 100%;
  `)}

  ${large(css`
    max-width: ${({ theme }) => (10 / theme.grid.columns.desktop) * 100}%;
  `)}
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const AuthWrapperGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AuthContent = styled.div`
  width: 100%;
  margin-top: ${rem(80)};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${rem(24)};
  ${medium(css`
    width: ${rem(700)};
  `)};
`;

export const AuthWrapperGridItem = styled(GridItem)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 0;

  // Target IE11 to make sure grid item takes up 100% height.
  @supports (-ms-high-contrast: none) {
    min-height: inherit;
  }
`;

export const AuthWrapperGridItemRight = styled(AuthWrapperGridItem)`
  display: none;

  ${medium(css`
    display: flex;
    flex: 1;
    margin-top: -${rem(TABLET_HEIGHT)};
  `)}
`;

export const AuthWrapperBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${rem(16)};
  width: 100%;
  margin-top: ${rem(31)};
  padding: ${rem(24)};

  ${medium(css`
    border: ${({ theme }) =>
      `${rem(1)} solid ${theme.colors.border.secondary}`};
    background-color: ${({ theme }) => theme.colors.background.input};
    padding: ${rem(32)};
  `)}
`;

export const SubdomainHeader = styled(MonoText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize20],
}))`
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const AuthWrapperTitle = styled(FeatureText).attrs(({ theme }) => ({
  level: 1,
  sizes: [
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize28,
    theme.typography.fontSizes.fontSize32,
    theme.typography.fontSizes.fontSize32,
  ],
  weight: theme.typography.fontWeights.medium,
  accessibilityAutoFocus: true,
}))`
  flex-shrink: 0;
  align-self: flex-start;
  ${({ centred = false }) =>
    centred ? `text-align: center` : createTextContentWidth()};
`;

export const AuthWrapperSerifTitle = styled(FeatureText).attrs(({ theme }) => ({
  fontFamily: FontFamilies.QuincyCF,
  sizes: [
    theme.typography.fontSizes.fontSize28,
    theme.typography.fontSizes.fontSize32,
    theme.typography.fontSizes.fontSize40,
    theme.typography.fontSizes.fontSize48,
  ],
  weight: theme.typography.fontWeights.bold,
  forwardedAs: 'h1',
}))`
  flex-shrink: 0;
  align-self: flex-start;
  ${createTextContentWidth()};
  margin: 0;
`;

export const AuthWrapperRightBlock = styled(AuthWrapperBlock)`
  flex: auto;
  padding: 0;

  ${small(css`
    padding: 0;
  `)}

  ${medium(css`
    padding: 0;
  `)}

  ${large(css`
    align-items: flex-start;
    padding: 0;
  `)}
`;

export const AuthWrapperSubtitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize24,
  ],
  weight: theme.typography.fontWeights.regular,
}))`
  flex-shrink: 0;
  width: 100%;
  align-self: flex-start;
`;

export const AuthWrapperContent = styled.div`
  padding: ${rem(47)} 0 0 0;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  width: 100%;
`;

export const AuthWrapperBlockContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${medium(css`
    max-width: ${rem(550)};
  `)}
`;

export const AuthWrapperFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: ${rem(16)} 0;
  width: 100%;

  ${small(css`
    padding: ${rem(32)} 0;
  `)}
`;

export const AlertContainer = styled.div`
  padding: 0 ${rem(16)};

  min-width: 95vw;
  margin-top: ${rem(20)};

  ${small(css`
    padding: 0 ${rem(36)};
  `)}

  ${medium(css`
    padding: 0 ${rem(52)};
  `)}
`;

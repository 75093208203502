import React from 'react';
import { rem } from 'polished';
import { styled } from 'styles';
import { IconType } from 'icons';
import DynamicIcon from '../../Shared/DynamicIcon/DynamicIcon';
import { AuthTypeEnum } from '../../__generated__/globalTypes';
import { BodyText } from '../../Shared/Typography';

const Card = styled.button<{ selected: boolean }>`
  background-color: ${({ theme }) => theme.colors.background.card};
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: ${rem(16)};
  border-radius: ${rem(16)};
  border: ${({ theme, selected }) =>
    selected
      ? `1.5px solid ${theme.colors.border.primary}`
      : `1px solid ${theme.colors.border.secondary}`};
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const TextWrapper = styled.div`
  text-align: left;
`;

const CardIconContainer = styled.div``;

const CardIcon = styled(DynamicIcon).attrs(() => ({
  width: 52,
  height: 52,
}))`
  aspect-ratio: 1;
  margin-right: ${rem(16)};
`;

const StyledHeading = styled(BodyText).attrs(({ theme }) => ({
  level: 2,
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.regular,
}))`
  margin-top: ${rem(6)};
`;

const Subheading = styled(BodyText).attrs(({ theme }) => ({
  level: 2,
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.regular,
}))`
  margin-top: ${rem(6)};
`;

interface Props {
  authMethod: AuthTypeEnum;
  label: string;
  ariaLabel: string;
  heading: string;
  subheading: string;
  Icon: IconType;
  selected: boolean;
  toggleAuthMethod(authMethod: string): void;
}

const AuthMethodCard = ({
  authMethod,
  ariaLabel,
  label,
  Icon,
  heading,
  subheading,
  toggleAuthMethod,
  selected,
}: Props) => (
  <Card
    role="radio"
    className={selected ? 'active' : ''}
    onClick={() => toggleAuthMethod(authMethod)}
    aria-label={ariaLabel}
    aria-checked={selected}
    selected={selected}
    data-testid={`auth-method-card-${label}`}
  >
    <CardIconContainer aria-hidden="true">
      <CardIcon Icon={Icon} />
    </CardIconContainer>
    <TextWrapper>
      <StyledHeading>{heading}</StyledHeading>
      <Subheading>{subheading}</Subheading>
    </TextWrapper>
  </Card>
);

export default AuthMethodCard;

import React from 'react';
import { IconProps } from '../IconProps';

const SvgLaptopBubble = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg fill="none" viewBox="0 0 52 52" width="1em" height="1em" {...props}>
    <rect width={52} height={52} rx={26} fill="#F1F0F5" />
    <rect width={52} height={52} rx={26} />
    <path
      d="M34.04 15H18.33c-.897 0-1.625.727-1.625 1.625v12.458c0 .898.728 1.625 1.625 1.625h15.71c.898 0 1.625-.727 1.625-1.625V16.625c0-.898-.727-1.625-1.625-1.625zm2.708 23.833H15.623a1.626 1.626 0 0 1-1.452-2.35l1.993-3.976a3.25 3.25 0 0 1 2.904-1.799h14.235a3.25 3.25 0 0 1 2.903 1.799l1.993 3.975a1.625 1.625 0 0 1-1.451 2.351v0zm-13-3.25h4.875"
      stroke="#1B1A29"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.248 18.792h-8.125c-.898 0-1.625.727-1.625 1.625v4.875c0 .897.727 1.625 1.625 1.625h8.125c.897 0 1.625-.728 1.625-1.625v-4.875c0-.898-.728-1.625-1.625-1.625z"
      stroke="#1B1A29"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.91 19.333l5.275 4.334 5.276-4.334"
      stroke="#1B1A29"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgLaptopBubble;

import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { Link } from 'react-router-dom';
import { EmailAddress } from '../EmailConfirmation/EmailConfirmation.styled';
import {
  AuthWrapperBlock,
  AuthWrapperContent,
  AuthWrapperFooter,
  AuthWrapperSubtitle,
  AuthWrapperTitle,
  AuthWrapper,
} from '../AuthWrapper';
import {
  EmailConfirmationFooter,
  EmailConfirmationHelperText,
} from '../EmailConfirmation/EmailConfirmationShared';

interface Props {
  name?: string;
  email?: string;
  progressIndicator: ReactElement;
  redirectTo: string;
}

function SignUpEmailConfirmation({
  name,
  email,
  progressIndicator,
  redirectTo,
}: Props) {
  const { t: translate } =
    useTranslation<Namespace<'logged_out'>>('logged_out');

  return (
    <AuthWrapper subdomain={name} progressBar={progressIndicator}>
      <AuthWrapperBlock data-testid="v2-email-confirmation-screen">
        <AuthWrapperTitle>
          {translate('sign_up.sign_up_email_confirmation.heading')}
        </AuthWrapperTitle>
        <AuthWrapperSubtitle data-testid="email-confirmation-subtitle">
          {Trans({
            t: translate,
            i18nKey: 'sign_up.sign_up_email_confirmation.subtitle',
            values: { email },
            defaults:
              'We’ve sent a verification email to <email_address>{{email}}</email_address>',
            components: { email_address: <EmailAddress /> },
          })}
        </AuthWrapperSubtitle>
        <AuthWrapperContent>
          <EmailConfirmationHelperText>
            {Trans({
              t: translate,
              i18nKey: 'sign_up.sign_up_email_confirmation.helper_text',
              values: { email },
              defaults:
                'Wrong email? <confirmation_link>Try again</confirmation_link>',
              components: { confirmation_link: <Link to={redirectTo} /> },
            })}
          </EmailConfirmationHelperText>
          <AuthWrapperFooter>
            <EmailConfirmationFooter userEmailAddress={email} />
          </AuthWrapperFooter>
        </AuthWrapperContent>
      </AuthWrapperBlock>
    </AuthWrapper>
  );
}

export default SignUpEmailConfirmation;

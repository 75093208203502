import { createConsoleLogger, LDUser } from 'launchdarkly-js-sdk-common';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import React, { Fragment } from 'react';
import { getMSTeamsUserContext, isMSTeams } from 'utils/MSTeams';
import getSubdomainFromUrl from '../utils/getSubdomainFromUrl';
import { LAUNCH_DARKLY_USER_KEY } from './withFeatureFlagUserContext';

const createFeatureFlagProvider = ({ key }: { key: string }) => {
  const subdomain = getSubdomainFromUrl();
  const teamsContext = getMSTeamsUserContext();
  const teamsTenantId = isMSTeams() ? teamsContext?.user?.tenant?.id : null;
  const user = JSON.parse(
    localStorage.getItem(LAUNCH_DARKLY_USER_KEY) || 'null',
  ) as LDUser;

  // ensures any users without a subdomain
  // in localStorage get a subdomain set
  if (user && !user.custom) {
    user.custom = {
      subdomain,
      ...(teamsTenantId && { tenantId: teamsTenantId }),
    };

    // store the updated user in local storage
    localStorage.setItem(LAUNCH_DARKLY_USER_KEY, JSON.stringify(user));
  }

  const FeatureFlagProvider = withLDProvider({
    clientSideID: key,
    options: {
      bootstrap: 'localStorage',
      logger:
        process.env.REACT_APP_ENVIRONMENT === 'development'
          ? createConsoleLogger('debug')
          : undefined,
    },
    user: user || {
      anonymous: true,
      custom: {
        subdomain,
        ...(teamsTenantId && { tenantId: teamsTenantId }),
      },
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  })(({ children }) => <Fragment>{children}</Fragment>);

  return FeatureFlagProvider;
};

export default createFeatureFlagProvider;

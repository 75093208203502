import { useQuery } from '@apollo/client';
import { Card } from '@unmind/design-system-components-web';
import RoutePath from 'App/RoutePath';
import { HELP_FOLDER_QUERY } from 'Track/Wellbeing/Score/CrisisHelpDisclaimer';
import { HelpFolderQuery } from 'Track/Wellbeing/Score/__generated__/HelpFolderQuery';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import { Namespace } from 'i18next';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { ExternalLink } from 'Shared/Typography';
import { LimitsHeader } from 'Talk/components/LimitsHeader';
import {
  MatchingCTA,
  PractitionerMatches,
  useMatches,
} from '../../components/Matching';
import { SetLocationForm } from '../../components/SetLocation';
import {
  LocationFormCopy,
  LocationFormTitle,
} from '../../components/SetLocation/styles';
import {
  BodyCopySmall,
  HeaderSlice,
  PageSubTitle,
  PageTitle,
} from '../common/styles';
import {
  CollapsingAccordionContainer,
  LandingPageBody,
  LocationFormContainer,
  StyledAccordion,
  MoreAboutTalkHeader,
  AccordionItemHeader,
  HeaderTitleSlice,
} from './styles';

export const LandingPage = () => {
  const { t } = useTranslation<Namespace<'talk'>>('talk');
  const aiMatchingEnabled = useFeatureFlag(FEATURE_FLAGS.TALK_ENABLE_MATCHING);
  const history = useHistory();
  const { data: helpFolderData } = useQuery<HelpFolderQuery>(HELP_FOLDER_QUERY);
  const {
    userHasPractitionerMatches,
    practitionerMatches,
    practitionerMatchesLoading,
    practitionerMatchesError,
  } = useMatches();

  const urgentHelpCategory = helpFolderData?.helpContentRoot?.items?.find(
    item => item?.folderType === 'Urgent',
  );

  const MatchingUI = () => {
    // Loading UI is handled within the PractitionerMatches component
    if (practitionerMatchesLoading) {
      return <></>;
    }

    if (practitionerMatchesError) {
      return <Redirect to={RoutePath.TalkBrowse} />;
    }

    return userHasPractitionerMatches ? (
      <PractitionerMatches
        matches={practitionerMatches}
        trackingSource="home_match"
      />
    ) : (
      <MatchingCTA />
    );
  };

  return (
    <>
      <HeaderTitleSlice>
        <PageTitle>{t('title')}</PageTitle>
        <LimitsHeader />
      </HeaderTitleSlice>
      {aiMatchingEnabled ? null : (
        <HeaderSlice>
          <PageSubTitle>{t('subtitle')}</PageSubTitle>
        </HeaderSlice>
      )}

      <LandingPageBody>
        {aiMatchingEnabled ? (
          <MatchingUI />
        ) : (
          <LocationFormContainer>
            <Card>
              <LocationFormTitle>{t('location_modal.title')}</LocationFormTitle>
              <LocationFormCopy>{t('location_modal.body')}</LocationFormCopy>
              <SetLocationForm
                onSubmitSuccess={() => history.push(RoutePath.TalkBrowse)}
              />
            </Card>
          </LocationFormContainer>
        )}
      </LandingPageBody>

      {aiMatchingEnabled &&
        !practitionerMatchesLoading &&
        !userHasPractitionerMatches && (
          <CollapsingAccordionContainer>
            <MoreAboutTalkHeader>{t('about_talk.title')}</MoreAboutTalkHeader>
            <StyledAccordion
              items={[
                {
                  header: (
                    <AccordionItemHeader>
                      {t('about_talk.for_me.title')}
                    </AccordionItemHeader>
                  ),
                  content: (
                    <BodyCopySmall>
                      <Trans
                        t={t}
                        i18nKey="about_talk.for_me.content"
                        components={{
                          styled_link: (
                            <ExternalLink
                              href={t('about_talk.for_me.url')}
                              rel="noopener noreferrer"
                              target="_blank"
                              aria-label={t('about_talk.for_me.title')}
                            />
                          ),
                        }}
                      />
                    </BodyCopySmall>
                  ),
                },
                {
                  header: (
                    <AccordionItemHeader>
                      {t('about_talk.how_talk_works.title')}
                    </AccordionItemHeader>
                  ),
                  content: (
                    <>
                      <BodyCopySmall>
                        {t('about_talk.how_talk_works.body_1')}
                      </BodyCopySmall>
                      <BodyCopySmall>
                        {t('about_talk.how_talk_works.body_2')}
                      </BodyCopySmall>
                      <BodyCopySmall>
                        {t('about_talk.how_talk_works.body_3')}
                      </BodyCopySmall>
                      <BodyCopySmall>
                        {t('about_talk.how_talk_works.body_4')}
                      </BodyCopySmall>
                    </>
                  ),
                },
                {
                  header: (
                    <AccordionItemHeader>
                      {t('about_talk.contact_in_crisis.title')}
                    </AccordionItemHeader>
                  ),
                  content: (
                    <BodyCopySmall>
                      <Trans
                        t={t}
                        i18nKey="about_talk.contact_in_crisis.content"
                        components={{
                          help_link: (
                            <Link
                              to={{
                                pathname: `${RoutePath.Help}/${urgentHelpCategory?.id}`,
                              }}
                            />
                          ),
                        }}
                      />
                    </BodyCopySmall>
                  ),
                },
              ]}
            />
          </CollapsingAccordionContainer>
        )}
    </>
  );
};

enum RoutePath {
  Account = '/account',
  Admin = '/admin',
  AdditionalInformationNeeded = '/additionalinformationneeded',
  AlternativeStartPagePlayer = '/player',
  AuthMethod = '/mixedmode',
  ChatbotLanding = '/coach',
  Chatbot = '/coach/chat',
  CheatSheet = '/cheat-sheet',
  CheckIn = '/checkin',
  CheckInConfirmation = '/checkinconfirmation',
  Compass = '/compass',
  CompassBenchmarking = '/compass-benchmarking',
  CompassCalculator = '/compass-calculator',
  CompassCheckIn = '/compass-checkin',
  CompassOnboarding = '/compass-onboarding',
  ConfirmSSOAccount = '/sso-confirm-account',
  Courses = '/courses',
  Dashboard = '/dashboard',
  Explore = '/explore',
  ExplicitPrivacyConsentNeeded = '/explicitprivacyconsentneeded',
  FindYourOrganisation = '/findteam',
  FindYourOrganisationConfirmation = '/teamemailconfirmation',
  ForgotPassword = '/forgotpassword',
  ForgotPasswordEmailConfirmation = '/emailconfirmation',
  Help = '/help',
  Home = '/home',
  InvalidInviteToken = '/signup/invalidinvitetoken',
  InviteColleague = '/account#invite-colleague',
  InviteFriend = '/account#invite-friend',
  InviteALovedOne = '/account#invite-a-loved-one',
  Login = '/',
  LoginWithCredentials = '/signin',
  LoginWithSSO = '/sso',
  MagicLogin = '/magicsignin',
  MagicLoginConfirmation = '/magicsigninconfirmation',
  MSTeamsSSO = '/msteamssso',
  MSTeamsSSOCallback = '/msteamsssocallback',
  InviteesLimitReached = '/signup/tokenunavailable',
  PlusOneSignUp = '/plusonesignup',
  ResetPassword = '/reset',
  Series = '/series',
  Shorts = '/shorts',
  SignInToYourOrganisation = '/team',
  SignUp = '/signup',
  SignUpConfirmation = '/signupconfirmation',
  SignUpPrivacy = '/signup/privacy',
  SignUpWorkDetails = '/signup/work-details',
  Sos = '/sos',
  SSOLoginHome = '/ssologinhome',
  SSOLoginSuccess = '/ssologinsuccess',
  SSOSignUp = '/sso-signup',
  SSOIdpInitatedLogin = '/sso-idp-login',
  StartSplitScreen = '/start',
  Talk = '/talk',
  TalkBrowse = '/talk/browse',
  Tools = '/tools',
  Tool = '/tools/:category/:tool',
  ToolsList = '/tools/:category/',
  Track = '/track',
  UnmindIndex = '/unmindindex',
  ValueOfCompass = '/value-of-compass',
  VerifyAccountOwnership = '/verify-account-ownership',
  VerifyAccountOwnershipEmailConfirmation = '/verify-account-ownership-email-confirmation',
  VoucherCodeSignUp = '/voucher',
  VoucherCodeSignUpConfirmation = '/voucherconfirmation',
  WelcomeToUnmind = '/welcome',
  YourCourses = '/your-courses',
}

export default RoutePath;

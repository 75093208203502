import { Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { rem } from 'polished';
import { useLazyQuery } from '@apollo/client';
import { styled, useTheme } from 'styles';
import { useTranslation } from 'react-i18next';
import {
  AuthWrapperBlock,
  AuthWrapperContent,
  AuthWrapperSubtitle,
  AuthWrapperTitle,
  AuthWrapper,
} from '../../AuthWrapper';
import { UserDetails } from '../SignUp';
import { tracking } from '../../../App/Tracking';
import RoutePath from '../../../App/RoutePath';
import i18n from '../../../i18n/config';
import BodyText from '../../../Shared/Typography/BodyText';
import EmailInputForm from './EmailInputForm';

const VoucherCodeText = styled(BodyText)`
  margin-top: ${rem(18)};
`;

export const ResponseMessageTypes = {
  'validate-workplace-email-not-recognised': i18n.t(
    'logged_out:sign_up.forms.work_email.errors.unrecognised_email',
  ),
  fallback: i18n.t('shared:errors.messages.failed'),
} as const;

interface WorkEmailFormProps {
  businessName: string;
  canUseVoucher: boolean;
  onSubmit(details: UserDetails): void;
  subdomain: string;
  progressIndicator: React.ReactElement;
  isInitialValid?: boolean;
  initialValues?: { emailInput: string };
}

interface FormFields {
  emailInput: string;
}

export const VALIDATE_EMAIL_QUERY = gql`
  query validateWorkplaceEmail($input: ValidateWorkplaceEmailInput!) {
    validateWorkplaceEmail(input: $input) {
      ... on ValidateWorkplaceEmailSuccess {
        isValid
      }
      ... on ValidateWorkplaceEmailNotFound {
        isValid
        message
      }
    }
  }
`;

const EnterWorkEmailForm = ({
  initialValues = { emailInput: '' },
  isInitialValid = false,
  ...props
}: WorkEmailFormProps) => {
  const formikRef = useRef<Formik<FormFields>>(null);
  const { t: translate } = useTranslation('logged_out');
  const theme = useTheme();
  const [error, setError] = useState('');
  const [validateEmailDomain, { loading }] = useLazyQuery(
    VALIDATE_EMAIL_QUERY,
    {
      onCompleted: data => {
        const {
          validateWorkplaceEmail: { isValid, message },
        } = data;

        if (isValid) {
          handleValidateEmailSuccess();
        } else {
          handleValidateEmailFailure(message);
        }
      },
      onError: () => {
        setError(ResponseMessageTypes.fallback);
      },
    },
  );

  const handleValidateEmailFailure = (
    message: keyof typeof ResponseMessageTypes,
  ) => {
    const errorMessage = ResponseMessageTypes[message]
      ? ResponseMessageTypes[message]
      : translate('sign_up.forms.work_email.errors.unrecognised_email');

    setError(errorMessage);

    tracking.track('employee-authentication', {
      subdomain: props.subdomain,
      verificationType: 'work-email',
      verificationValid: false,
      partner: null,
    });
  };

  const handleValidateEmailSuccess = () => {
    setError('');

    tracking.track('employee-authentication', {
      subdomain: props.subdomain,
      verificationType: 'work-email',
      verificationValid: true,
      partner: null,
    });

    props.onSubmit({
      email: formikRef?.current?.state.values.emailInput,
    });
  };

  const onSubmit = (values: FormFields) => {
    validateEmailDomain({
      variables: {
        input: {
          email: values.emailInput,
          subdomain: props.subdomain,
        },
      },
    });
  };

  return (
    <AuthWrapper
      subdomain={props.businessName}
      progressBar={props.progressIndicator}
    >
      <AuthWrapperBlock data-testid={'signup-email-form'}>
        <AuthWrapperTitle data-testid="form-title">
          {translate('sign_up.forms.work_email.heading')}
        </AuthWrapperTitle>
        <AuthWrapperSubtitle data-testid="form-subtitle">
          {translate('sign_up.forms.work_email.subtitle')}
        </AuthWrapperSubtitle>
        <AuthWrapperContent>
          <EmailInputForm
            isInitialValid={isInitialValid}
            initialValues={initialValues}
            onSubmit={onSubmit}
            setError={setError}
            error={error}
            loading={loading}
            formikRef={formikRef}
          />
          {props.canUseVoucher ? (
            <VoucherCodeText sizes={[theme.typography.fontSizes.fontSize16]}>
              {translate('sign_up.forms.work_email.voucher_code_text')}{' '}
              <Link to={RoutePath.VoucherCodeSignUp}>
                {translate('sign_up.forms.work_email.voucher_code_link.text')}
              </Link>
            </VoucherCodeText>
          ) : null}
        </AuthWrapperContent>
      </AuthWrapperBlock>
    </AuthWrapper>
  );
};

export default EnterWorkEmailForm;

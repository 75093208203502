import { showToast } from '@unmind/design-system-components-web';
import RoutePath from 'App/RoutePath';
import { tracking } from 'App/Tracking';
import { talkEventsQuery_unmindEvents_edges_node as TalkEvent } from 'Services/BeGateway/Talk/__generated__/talkEventsQuery';
import { StylelessButton } from 'Shared/Button';
import { useModalState } from 'Shared/Modal';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { CancelEvent } from '../CancelEvent';
import { Dropdown, DropdownMenuItem } from '../Dropdown';
import { EditTriggerButtonText } from './styles';

interface Props {
  event: TalkEvent;
  isDisabled?: boolean;
  numEvents: number;
}

export enum KeyCode {
  Enter = 'Enter',
  Escape = 'Escape',
}

export const EditEvent = ({ event, isDisabled, numEvents }: Props) => {
  const aiMatchingEnabled = useFeatureFlag(FEATURE_FLAGS.TALK_ENABLE_MATCHING);
  const { t: translate } = useTranslation('talk');
  const history = useHistory();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const {
    show: showEventCancellationModal,
    setShow: setShowEventCancellationModal,
    closeModal: closeEventCancellationModal,
  } = useModalState();

  const onCancelEventSuccess = () => {
    if (numEvents === 1 && !aiMatchingEnabled) {
      history.push(RoutePath.TalkBrowse);
    }
    showToast(translate('upcoming_events.cancel_event.form.success_message'));
  };

  const onMenuOpen = () => {
    if (isEditDrawerOpen) {
      setIsEditDrawerOpen(false);
    } else {
      if (event.id) {
        tracking.track('talk-edit-session-pressed', {
          eventId: event.id,
        });
      }
      setIsEditDrawerOpen(true);
    }
  };

  const trackModalOpen = () => {
    if (event.id) {
      tracking.track('talk-cancel-session-pressed', {
        eventId: event.id,
      });
    }
  };

  return (
    <>
      <Dropdown
        isOpen={isEditDrawerOpen && !isDisabled}
        closeDrawer={() => setIsEditDrawerOpen(false)}
        triggerElement={
          <StylelessButton
            disabled={isDisabled}
            onClick={onMenuOpen}
            data-cy="edit-talk-event-button"
          >
            <EditTriggerButtonText>
              {translate('upcoming_events.edit_event.edit_button.label')}
            </EditTriggerButtonText>
          </StylelessButton>
        }
        menuItems={[
          <DropdownMenuItem
            role="menuitem"
            label={translate('upcoming_events.edit_event.options.cancel.label')}
            onClick={() => {
              trackModalOpen();
              setShowEventCancellationModal(true);
            }}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === KeyCode.Enter) {
                trackModalOpen();
                setShowEventCancellationModal(true);
              } else if (e.key === KeyCode.Escape) {
                setIsEditDrawerOpen(false);
              }
            }}
            data-cy="cancel-talk-event-button"
          />,
        ]}
      />

      <CancelEvent
        event={event}
        open={showEventCancellationModal}
        onCancelEventSuccess={onCancelEventSuccess}
        onClose={() => {
          tracking.track('talk-session-cancellation-abandoned', {
            eventId: event.id,
          });
          closeEventCancellationModal();
          setIsEditDrawerOpen(false);
        }}
      />
    </>
  );
};

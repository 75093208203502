import { rem } from 'polished';
import React from 'react';
import { defaultTheme, styled } from 'styles';
import { LockShield, EyeLid } from 'icons';
import { useTranslation } from 'react-i18next';
import { isMSTeams } from 'utils/MSTeams';
import RoutePath from '../../App/RoutePath';
import { tracking } from '../../App/Tracking';
import PrimaryButton from '../../Shared/PrimaryButton';
import ExternalLink from '../../Shared/Typography/ExternalLink';
import {
  AuthWrapper,
  AuthWrapperTitle,
  AuthWrapperContent,
  AuthWrapperBlockContainer,
} from '../AuthWrapper';
import SectionIcon from '../SectionIcon';
import getSubdomainFromUrl from '../../utils/getSubdomainFromUrl';
import { useIsVirginPulseGroup } from '../VirginPulse/useIsVirginPulseGroup';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import { VIRGIN_PULSE_PARTNER_NAME } from '../VirginPulse/consts';
import { AuthTypeEnum } from '../../__generated__/globalTypes';
import { HeadingText, BodyText } from '../../Shared/Typography';
import { useSubdomainInfo } from './useSubdomainInfo';

const Wrapper = styled.div`
  margin-top: ${rem(90)};
`;

const PrivacySection = styled.div`
  margin-bottom: ${rem(32)};
`;

const PrivacySectionTitle = styled(HeadingText).attrs(({ theme }) => ({
  level: 2,
  sizes: [
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize24,
  ],
  weight: theme.typography.fontWeights.medium,
}))`
  margin-bottom: ${rem(6)};
`;

const PrivacySectionBody = styled(BodyText).attrs(({ theme }) => ({
  forwardedAs: 'p',
  sizes: [
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize20,
  ],
}))`
  margin: 0;
`;

const PrivacyPolicyLink = styled(ExternalLink).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize12,
    theme.typography.fontSizes.fontSize12,
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
  ],
  color: theme.colors.text.link,
  weight: theme.typography.fontWeights.regular,
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${rem(28)};
`;

export const getContinueLink = ({
  groupAuthType,
  isClientDomain,
}: {
  groupAuthType: AuthTypeEnum | null;
  isClientDomain: boolean;
}) => {
  // If MS teams, always render the find your org page
  if (isMSTeams()) {
    return `${RoutePath.SignInToYourOrganisation}?redirect=/signup`;
  }
  // If we're not yet on the client domain, direct to find your org page
  if (!isClientDomain) {
    return `${RoutePath.SignInToYourOrganisation}?redirect=/signup`;
  }

  if (groupAuthType === AuthTypeEnum.AUTH0_SSO) {
    return `${RoutePath.LoginWithSSO}`;
  } else if (groupAuthType === AuthTypeEnum.LEGACY_CREDENTIALS) {
    return `${RoutePath.SignUp}`;
  } else if (groupAuthType === AuthTypeEnum.MIXED_MODE) {
    return `${RoutePath.AuthMethod}?redirect=/signup`;
  }
};

export function Privacy() {
  const subdomain = getSubdomainFromUrl();
  const { t: translate } = useTranslation('logged_out');
  const { isVirginPulseGroup, loading: virginPulseGroupIsLoading } =
    useIsVirginPulseGroup();
  const partner = isVirginPulseGroup ? VIRGIN_PULSE_PARTNER_NAME : null;

  const { group, loading: authTypeLoading } = useSubdomainInfo({
    subdomain,
  });

  if (virginPulseGroupIsLoading || authTypeLoading) {
    return <LoadingIndicator />;
  }

  const { authType } = group ?? { authType: null };

  const continueLink = getContinueLink({
    groupAuthType: authType,
    isClientDomain: subdomain !== 'search',
  });

  return (
    <AuthWrapper showFooter={false}>
      <AuthWrapperBlockContainer>
        <Wrapper>
          <AuthWrapperTitle centred data-testid="form-title">
            {translate('sign_up.privacy.heading')}
          </AuthWrapperTitle>
          <AuthWrapperContent>
            <PrivacySection>
              <PrivacySectionTitle>
                <SectionIcon
                  icon={EyeLid}
                  primaryColour={
                    defaultTheme.colors.signup.privacyIconForeground
                  }
                  secondaryColour={
                    defaultTheme.colors.signup.privacyIconBackground
                  }
                />
                {translate('sign_up.privacy.confidential_info_title')}
              </PrivacySectionTitle>
              <PrivacySectionBody>
                {translate('sign_up.privacy.confidential_info_text')}
              </PrivacySectionBody>
            </PrivacySection>

            <PrivacySection>
              <PrivacySectionTitle>
                <SectionIcon
                  icon={LockShield}
                  primaryColour={
                    defaultTheme.colors.signup.privacyIconForeground
                  }
                  secondaryColour={
                    defaultTheme.colors.signup.privacyIconBackground
                  }
                />
                {translate('sign_up.privacy.anonymised_info_title')}
              </PrivacySectionTitle>
              <PrivacySectionBody>
                {translate('sign_up.privacy.anonymised_info_text')}
              </PrivacySectionBody>
            </PrivacySection>

            <PrimaryButton
              data-testid="continue-button"
              label={translate('sign_up.privacy.continue_button.label')}
              to={continueLink}
              onClick={() => {
                tracking.track('signup-privacy-page-continue-clicked', {
                  partner,
                });
              }}
            />
            <PrivacyPolicyLink
              data-testid="privacy-policy-link"
              href={translate('sign_up.privacy.privacy_policy_link.href')}
              aria-label={translate(
                'sign_up.privacy.privacy_policy_link.a11y_label',
              )}
              target="_blank"
              onClick={() => {
                tracking.track('privacy-policy-clicked', { partner });
              }}
            >
              {translate('sign_up.privacy.privacy_policy_link.text')}
            </PrivacyPolicyLink>
          </AuthWrapperContent>
        </Wrapper>
      </AuthWrapperBlockContainer>
    </AuthWrapper>
  );
}

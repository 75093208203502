import { rem } from 'polished';
import { BodyText } from '@unmind/design-system-components-web';
import { Message } from 'Assistant/Chatbot/types';
import { ThemeInterface, css, styled } from 'styles';

export const MessageCard = styled.div<{
  sender: Message['role'];
  hasOptions?: boolean;
}>`
  position: relative;
  border-radius: 10px;
  font-size: 16px;
  display: flex;
  flex-direction: ${({ hasOptions }) => (hasOptions ? 'column' : 'row')};
  gap: 16px;
  width: 100%;

  ${({ sender, theme }) =>
    sender === 'user' &&
    css`
      background-color: ${theme.colors.chatbot.messages.user.background};
      padding: ${rem(0)} ${rem(16)} ${rem(0)} ${rem(16)};
    `}

  ${({ sender, theme }) =>
    sender === 'assistant' &&
    css`
      background-color: ${theme.colors.background.primary};
      padding: ${rem(16)};
    `}
`;

export const MessageText = styled(BodyText).attrs(
  ({ theme, sender }: { theme: ThemeInterface; sender: Message['role'] }) => ({
    sizes: [theme.typography.fontSizes.fontSize16],
    weight: theme.typography.fontWeights.regular,
    color:
      sender === 'user'
        ? theme.colors.chatbot.messages.user.text
        : theme.colors.chatbot.messages.assistant.text,
  }),
)<{ sender: Message['role'] }>`
  word-wrap: anywhere;

  & :first-child {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

export const MessageTextWrapper = styled.div<{
  isLoading?: boolean;
  hasOptions?: boolean;
}>`
  position: relative;
  border-radius: 10px;
  font-size: 16px;
  display: flex;
  gap: 16px;
  width: 100%;
  flex-direction: ${({ hasOptions }) => (hasOptions ? 'row' : 'column')};

  ${({ isLoading }) =>
    isLoading &&
    css`
      flex-direction: row;
    `}

  & a {
    text-decoration: underline;
    text-underline-offset: 0.25em;
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

export const LoadingWrapper = styled.div`
  align-self: center;
`;

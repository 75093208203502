import { isMSTeams } from './MSTeams';

export type Subdomain = string;

const getMSTeamsSubdomain = (): Subdomain => {
  // Pull subdomain from local storage as that's how we store the subdomain in MS Teams
  let subdomain = localStorage.getItem('subdomain');
  if (subdomain) {
    return subdomain;
  } else {
    // If no sub-domain in local storage, try pull from the URL instead - this is required for dev environments
    subdomain = window.location.hostname.split('.')[0];
    localStorage.setItem('subdomain', subdomain);

    return subdomain;
  }
};

export default function getSubdomainFromUrl(): Subdomain {
  if (isMSTeams()) {
    return getMSTeamsSubdomain();
  } else {
    return window.location.hostname.split('.')[0];
  }
}

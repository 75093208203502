import React, { useState } from 'react';
import { Formik } from 'formik';
import { rem } from 'polished';
import { compose } from 'recompose';
import { useHistory } from 'react-router';
import { styled } from 'styles';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RoutePath from '../../App/RoutePath';
import { tracking } from '../../App/Tracking';
import { AlertBox } from '../../Shared/Form';
import { FormikInput, validateEmail } from '../../Shared/Form/Formik';
import {
  withSubdomainFromUrl,
  ChildProps as SubdomainFromUrlProps,
} from '../withSubdomainFromUrl';
import {
  AuthWrapperBlock,
  AuthWrapperTitle,
  AuthWrapperContent,
  AuthWrapperSubtitle,
  AuthWrapper,
} from '../AuthWrapper';
import {
  StyledForm,
  StyledFormFieldLabel,
  StyledSubmitButton,
} from '../SignUp/Forms/CommonFormStyledComponents';
import { useSubdomainInfo } from '../SignUp/useSubdomainInfo';
import { useMagicLogin } from './useMagicLogin';

export type MagicLinkProps = SubdomainFromUrlProps;

const StyledAlertBox = styled(AlertBox)`
  margin-bottom: ${rem(16)};
`;

export function MagicLogin(props: MagicLinkProps) {
  const { subdomain } = props;
  const { groupName, groupId } = useSubdomainInfo({
    subdomain,
  });
  const { requestMagicLogin, loading } = useMagicLogin();
  const { t: translate } = useTranslation(['logged_out', 'shared']);
  const history = useHistory();
  const [errorState, setErrorState] = useState<
    { message: string; type: string } | undefined
  >();

  const initialValues = {
    emailInput: '',
  };

  const errorMessage = translate('shared:errors.messages.something_wrong');

  async function onSubmit(email: string) {
    if (!groupId || !email) {
      setErrorState({
        message: errorMessage,
        type: 'general',
      });

      return;
    }

    setErrorState(undefined);

    const { success, error } = await requestMagicLogin({
      email,
      groupId,
    });

    if (success) {
      tracking.track('magic-link-sent', { Subdomain: subdomain });
      history.push(RoutePath.MagicLoginConfirmation, { email });

      return;
    }

    if (error) {
      if (error.message.toLowerCase().indexOf('rate limit') >= 0) {
        setErrorState({
          message: errorMessage,
          type: 'rate-limit',
        });
      } else {
        setErrorState({
          message: errorMessage,
          type: 'general',
        });
      }
    }
  }

  return (
    <AuthWrapper subdomain={groupName}>
      <AuthWrapperBlock>
        <AuthWrapperTitle data-testid="title">
          {translate('logged_out:login.forms.magic_login.heading')}
        </AuthWrapperTitle>
        <AuthWrapperSubtitle data-testid="subtitle">
          {translate('logged_out:login.forms.magic_login.subtitle')}
        </AuthWrapperSubtitle>

        <AuthWrapperContent>
          <Formik
            initialValues={initialValues}
            onSubmit={async values => onSubmit(values.emailInput)}
          >
            {({ isValid }) => (
              <StyledForm>
                <StyledFormFieldLabel
                  data-testid="email-input-label"
                  htmlFor="emailInput"
                >
                  {translate('logged_out:login.forms.magic_login.email_label')}
                </StyledFormFieldLabel>
                <FormikInput
                  data-testid="email-input"
                  name="emailInput"
                  placeholder={translate(
                    'logged_out:login.forms.magic_login.email_placeholder',
                  )}
                  aria-label={translate(
                    'logged_out:login.forms.magic_login.email_label',
                  )}
                  type="email"
                  validate={validateEmail}
                />
                <StyledSubmitButton
                  data-testid="submit-button"
                  type="submit"
                  label={translate(
                    'logged_out:login.forms.magic_login.submit_button.label',
                  )}
                  disabled={!isValid}
                  loading={loading}
                />

                {Boolean(errorState) && (
                  <StyledAlertBox
                    alertType="failed"
                    data-testid={`error-${errorState?.type}`}
                    message={errorState?.message}
                  />
                )}
              </StyledForm>
            )}
          </Formik>
        </AuthWrapperContent>
      </AuthWrapperBlock>
    </AuthWrapper>
  );
}

export default compose<MagicLinkProps, RouteComponentProps>(
  withSubdomainFromUrl,
)(MagicLogin);

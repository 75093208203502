import { logException } from '../../App/logging';

export const defaultAuthErrorMessage =
  'logged_out:login.errors.sso_authentication_error';

export const MSTeamsAuthPopupErrorMessage = 'CancelledByUser';

export const authenticationErrorStorageCode = 'authentication.error';

export enum AuthErrorCode {
  ACCESS_REVOKED = 'ACCESS_REVOKED',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  IDP_ATTRIBUTES_MISSING = 'IDP_ATTRIBUTES_MISSING',
  AUTH_CANCELLED_BY_USER = 'AUTH_CANCELLED_BY_USER',
}

const errorMessageMap: Record<AuthErrorCode, string> = {
  [AuthErrorCode.ACCESS_REVOKED]:
    'logged_out:login.errors.account_access_revoked',
  [AuthErrorCode.INTERNAL_SERVER_ERROR]: defaultAuthErrorMessage,
  [AuthErrorCode.IDP_ATTRIBUTES_MISSING]:
    'logged_out:login.errors.sso_missing_attributes',
  [AuthErrorCode.AUTH_CANCELLED_BY_USER]: defaultAuthErrorMessage,
};

export const getAuthErrorMessage = (urlSearchParams: URLSearchParams) => {
  const errorCode = urlSearchParams.get('error_code') as AuthErrorCode;

  if (errorCode) {
    logException(new Error('SSO login error'), {
      tags: {
        code: errorCode,
      },
    });
  }

  let errorMessage = defaultAuthErrorMessage;

  if (Object.values(AuthErrorCode).includes(errorCode)) {
    errorMessage = errorMessageMap[errorCode];
  }

  return { errorCode, errorMessage };
};

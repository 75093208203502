import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { AUTHENTICATED_QUERY } from 'App/Auth/authenticatedQuery';
import { MutationFunction, isApolloError } from '@apollo/client';
import {
  AuthApiResponse,
  AuthPayload,
  AuthStatus,
  getAuthPayload,
} from '../../App/Auth';

import { Errors } from '../../Shared/Errors';

export const LOGIN_MUTATION = gql`
  mutation Login(
    $emailAddress: String!
    $password: String!
    $groupId: String!
    $isMobile: Boolean
  ) {
    login(
      email: $emailAddress
      password: $password
      groupId: $groupId
      isMobile: $isMobile
    ) {
      activationDate
      id
      token
      iris_token
      email
      firstName
      lastName
      userType {
        name
      }
      permissions {
        value
      }
      createdAt
      groupId
      groupName
      clientTier
      subdomain
      praiseDirectory {
        value
      }
      departmentId
      departmentName
      locationId
      userTraits
      locationName
      abTestCohorts
    }
  }
`;

export interface LoginAPIResponse {
  login: AuthApiResponse;
}

export interface WithLoginChildProps {
  login(variables: LoginVariables): Promise<AuthPayload>;
}

export interface LoginVariables {
  emailAddress: string;
  password: string;
  groupId?: string | null;
  isMobile?: boolean;
}

export const login = async (
  variables: LoginVariables,
  mutate?: MutationFunction<LoginAPIResponse, LoginVariables>,
): Promise<AuthPayload> => {
  try {
    if (mutate) {
      const response = await mutate({
        variables: variables,
      });

      if (response) {
        if (response.data && response.data.login !== undefined) {
          return getAuthPayload(response.data.login);
        }
      }
    }
  } catch (error) {
    if (error instanceof Error && isApolloError(error)) {
      const errorType =
        error.graphQLErrors[0] !== undefined ? error.graphQLErrors[0].name : '';

      let errorTypeToReturn = Errors.ServerError;
      if (errorType === Errors[Errors.AccountLockedError]) {
        errorTypeToReturn = Errors.AccountLockedError;
      }
      if (errorType === Errors[Errors.InvalidCredentialsError]) {
        errorTypeToReturn = Errors.InvalidCredentialsError;
      }

      return {
        error: errorTypeToReturn,
        status: AuthStatus.ERROR,
      };
    }
  }

  return {
    error: Errors.ServerError,
    status: AuthStatus.ERROR,
  };
};

export default graphql<
  Record<string, unknown>,
  LoginAPIResponse,
  LoginVariables,
  WithLoginChildProps
>(LOGIN_MUTATION, {
  options: {
    update: cache => {
      cache.writeQuery({
        query: AUTHENTICATED_QUERY,
        data: {
          // unmind-web
          authenticated: true,
        },
      });
    },
    fetchPolicy: 'no-cache',
    context: {
      timeout: 15000,
    },
  },
  props: ({ mutate }) => ({
    login: async (variables: LoginVariables) => login(variables, mutate),
  }),
});

import { styled } from 'styles';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { Tick } from 'icons';
import { rem } from 'polished';
import { useMutation } from '@apollo/client';
import { isMSTeams } from 'utils/MSTeams';
import { tracking } from '../../App/Tracking';
import { LOG_EVENT_MUTATION } from '../../App/Tracking/serverside/withLogEvent';
import { BodyText } from '../../Shared/Typography';
import getSubdomainFromUrl from '../../utils/getSubdomainFromUrl';
import PrimaryButton from '../../Shared/PrimaryButton';

export const MS_TEAMS_INVITE_COLLEAGUE_URL =
  'https://teams.microsoft.com/l/app/c7d6307e-8186-438f-a33f-66b22f269324';

const Text = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize12,
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize18,
  ],
}))`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 18px 0 12px 12px;
`;

const TickIcon = styled(Tick).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  width: ${rem(21)};
  height: ${rem(21)};
  margin-right: 9px;
`;

const CopyLinkContainer = styled.div`
  display: flex;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  justify-content: space-between;
`;

const CopyLinkText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize12,
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize18,
  ],
}))`
  color: ${({ theme }) => theme.button.primary.default.textColor};
`;

const CopyLinkButton = styled(PrimaryButton)`
  margin: 6px;
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`;

export const CopyLink = () => {
  const { t: translate } = useTranslation<Namespace<'shared'>>('shared');
  const [logEvent] = useMutation(LOG_EVENT_MUTATION);

  const buttonTextDuringTimeout = translate('copy_link.on_click');
  const buttonText = translate('copy_link.default');

  const [currentButtonText, setButtonText] = useState(buttonText);
  const [timeOutIsExecuting, setTimeOutIsExecuting] = useState(false);

  const subdomain = getSubdomainFromUrl();
  // show the msteams app link when web app is rendered in msteams
  const url = isMSTeams()
    ? MS_TEAMS_INVITE_COLLEAGUE_URL
    : `https://${subdomain}.unmind.com/start?utm_source=colleague_referral`;

  let timer = 0;

  const selectRef = useRef<any>(null);

  const onButtonTextClicked = async () => {
    if (!timeOutIsExecuting) {
      setTimeOutIsExecuting(true);
      setButtonText(buttonTextDuringTimeout);

      if (isMSTeams()) {
        // workaround for MS Teams as the clipboard API isn't available
        // https://stackoverflow.com/questions/73552314/how-can-i-get-clipboard-permission-in-teams-add-on-app
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(selectRef.current);
        selection?.removeAllRanges();
        selection?.addRange(range);
        document.execCommand('copy');
      } else {
        await navigator.clipboard.writeText(url);
      }

      tracking.track('invite-a-colleague-share-link-clicked');
      void logEvent({
        variables: {
          input: {
            eventName: 'COLLEAGUE_INVITE_CTA_CLICKED',
          },
        },
      });

      timer = window.setTimeout(() => {
        setButtonText(buttonText);
        setTimeOutIsExecuting(false);
      }, 3000);
    }
  };

  useEffect(() => () => window.clearTimeout(timer), [timer]);

  return (
    <CopyLinkContainer>
      <Text ref={selectRef}>{url}</Text>
      <CopyLinkButton
        onClick={onButtonTextClicked}
        label={
          <LabelContainer>
            {timeOutIsExecuting ? <TickIcon /> : null}
            <CopyLinkText>{currentButtonText}</CopyLinkText>
          </LabelContainer>
        }
      />
    </CopyLinkContainer>
  );
};
